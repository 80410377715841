import styled from 'styled-components';

import { spacing } from '@/global/style/variables';

export const Wrapper = styled.div`
  display: block;
  position: absolute;

  z-index: 90;
  transition: all 0.25s ease;
  opacity: 0;
  transform: translateY(${spacing.x5});
  pointer-events: none;

  margin-right: ${spacing.x1};
  width: calc(100% - ${spacing.x2});

  &[data-state='visible'] {
    pointer-events: all;
    opacity: 1;
    transform: translateY(${spacing.x25});
  }
`;
