import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { HeaderComplementaryNavigation, HeaderIconLink } from '@/types';
import { ForwardedRef, forwardRef } from 'react';
import { FontWeight } from '@/constants';
import HamburgerIcon from './HamburgerIcon/HamburgerIcon';
import {
  ActiveIndicator,
  ButtonWrapper,
  IconContainer,
  MenuIconLink,
  MenuIconLinkText,
  SecondarySvgIcon,
} from './MainIconLink.styled';

type MainIconLinksProps = {
  ['data-testid']: string;
  ariaLabel?: string;
  active?: boolean;
  children?: React.ReactNode;
  customIcon?: 'animated-menu';
  iconLink: HeaderComplementaryNavigation | HeaderIconLink;
  ignoreOutsideClick?: boolean;
  ignoreOutsideClickClassName?: string;
  isMinimal?: boolean;
  isOpen?: boolean;
  onClick?: () => void;
  secondaryIcon?: IconKey;
  shouldRotateSecondaryIcon?: boolean;
};

const MainIconLink = forwardRef((props: MainIconLinksProps, ref: ForwardedRef<HTMLLIElement>) => {
  const {
    'data-testid': dataTestId,
    active = false,
    children,
    customIcon,
    iconLink,
    ignoreOutsideClick,
    ignoreOutsideClickClassName = 'ignore-outside-click',
    isMinimal,
    isOpen = false,
    onClick = undefined,
    secondaryIcon = null,
    shouldRotateSecondaryIcon = false,
    ariaLabel,
  } = props;

  const handleClick = () => {
    if (onClick) return onClick();

    window.location.href = iconLink.link.url;
  };

  const getIconClassNameByIcon = () => {
    type IconLink = 'help' | 'phone' | 'shoppingBag' | 'myPages';
    const iconLinkKey = iconLink.icon as IconLink;

    const iconClassMap: Record<IconLink, string> = {
      help: 'help',
      phone: 'fill',
      shoppingBag: 'checkout',
      myPages: 'my-pages',
    };

    return iconClassMap[iconLinkKey] || 'unknown';
  };

  const clickOutsideClass = ignoreOutsideClick ? ignoreOutsideClickClassName : '';
  const classNames = `menu-icon-link-${getIconClassNameByIcon()} ${clickOutsideClass}`;

  return (
    <MenuIconLink ref={ref} className={classNames} $isMinimal={isMinimal}>
      <ButtonWrapper
        aria-label={ariaLabel}
        data-testid={dataTestId}
        type="button"
        $isOpen={isOpen}
        onClick={handleClick}
      >
        <IconContainer>
          {customIcon === 'animated-menu' ? <HamburgerIcon isOpen={isOpen} /> : <SvgIcon icon={iconLink.icon} />}

          {secondaryIcon && (
            <SecondarySvgIcon size="small" icon={secondaryIcon} $rotate={shouldRotateSecondaryIcon && isOpen} />
          )}
        </IconContainer>
        <MenuIconLinkText
          text={iconLink.text}
          type={TypographyType.Micro}
          fontWeight={FontWeight.Medium}
          align="center"
        />
      </ButtonWrapper>
      <ActiveIndicator $active={active} />

      {children && children}
    </MenuIconLink>
  );
});

MainIconLink.displayName = 'MainIconLink';

export default MainIconLink;
