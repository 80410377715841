import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const MenuIconLink = styled.li<{ $isMinimal?: boolean }>`
  cursor: pointer;
  margin-right: ${spacing.x3};
  position: relative;
  padding-top: ${spacing.x1};
  ${above.md`
    padding-top: ${spacing.x05};
  `}

  // depending on the screen size, some icons are hidden. Only Login/My pages is always visible
  // minimal header (shown in the login page) will always only show the Help icon
  &.menu-icon-link-checkout {
    display: none;

    ${above.xs`
      display: ${({ $isMinimal }) => ($isMinimal ? 'none' : 'block')};
    `}
  }

  &.menu-icon-link-fill {
    display: none;

    ${above.sm`
      display: ${({ $isMinimal }) => ($isMinimal ? 'none' : 'block')};
    `}
  }

  // minimal header will only show the help-icon link. That should always be visible in the minimal header
  &.menu-icon-link-help {
    display: ${({ $isMinimal }) => ($isMinimal ? 'block' : 'none')};
    margin-right: ${({ $isMinimal }) => $isMinimal && '0px'};

    ${above.sm`
      display: block;
    `}
  }

  ${above.sm`
    margin-right: ${spacing.x2};
  `}

  &:last-of-type {
    margin-right: 0px;
  }
`;

export const ButtonWrapper = styled.button<{ $isOpen: boolean }>`
  display: grid;
  gap: ${spacing.x05};
  margin-bottom: ${spacing.x05};
  padding: ${spacing.x05};
  border-radius: ${BorderRadius.Small};
  border: 2px solid ${Color.Transparent};
  outline: none;

  ${above.md`
    margin-bottom: ${spacing.x15};
  `}

  &:hover {
    background-color: ${Color.MineShaftHover};
  }

  &:hover&:active {
    background-color: ${Color.MineShaftActive};
  }

  &:focus-visible {
    border-color: ${Color.Ocean};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    background-color: ${Color.MineShaftHover};
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SecondarySvgIcon = styled(SvgIcon)<{ $rotate: boolean }>`
  margin-left: -${spacing.x1};
  transition: transform 0.2s ease;
  transform: rotate(0deg);

  ${(props) =>
    props.$rotate &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ActiveIndicator = styled.div<{ $active: boolean }>`
  width: calc(100% - ${spacing.x2});
  margin: 0 auto;
  border: 2px solid ${Color.Transparent};

  ${({ $active }) => $active && `border-color: ${Color.Blue};`}
`;

export const MenuIconLinkText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
`;
