import styled from 'styled-components';

import { Color, spacing } from '@/global/style/variables';

// Light hover bg
export const RadioInputContainer = styled.div<{ $disabled: boolean; $hoverVisible: boolean }>`
  display: inline-flex;
  border-radius: 50%;
  padding: ${spacing.x05};
  transition: background-color 0.1s ease-in;

  ${({ $disabled, $hoverVisible }) =>
    !$disabled &&
    $hoverVisible &&
    `&:hover {
      background-color: ${Color.DarkGray1Hover};
  }`}
`;

function getFocusColor($focusVisible: boolean) {
  if (!$focusVisible) {
    return Color.Transparent;
  }

  return Color.Blue;
}

export const RadioInput = styled.input<{ $focusVisible: boolean; disabled: boolean }>`
  position: relative;
  appearance: none;
  display: inline-flex;
  align-items: center;
  outline: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: ${({ checked, disabled }) => {
    if (disabled && checked) return Color.DarkGray2;
    if (checked) return Color.Blue;

    return Color.Transparent;
  }};

  border: 2px solid
    ${({ checked, disabled }) => {
      if (disabled) return Color.DarkGray2;
      if (checked) return Color.Blue;

      return Color.DarkGray1;
    }};

  // white dot in the center to indicate checked
  ${({ checked }) =>
    checked &&
    `
    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: calc(50% - 4px);
      background-color: ${Color.White};
    }
  `}

  // focus ring around the radio button
  &::after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    left: -6px;
    border: 2px solid transparent;
  }

  // and when the radio button is focused, the ring gets a blue color
  &:focus-visible {
    &::after {
      border-color: ${({ $focusVisible }) => getFocusColor($focusVisible)};
    }
  }
`;

export const Label = styled.label<{ $disabled: boolean }>`
  font-size: 1.6rem;
  margin-left: ${spacing.x15};
  position: relative;
  top: -${spacing.x05};
  display: inline-block;

  ${({ $disabled }) => `cursor: ${$disabled ? 'default' : 'pointer'}`};
`;
