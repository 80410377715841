import Button from '@/newComponents/Button/Button';
import { ButtonSize } from '@/newComponents/Button/Button.utils';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { FooterLinkItem, FooterSectionItemType } from '@/types';

import {
  SectionContent,
  SectionItem,
  StyledLink,
} from '@/global/layouts/parts/Footer/FooterLinks/FooterLinkSection/FooterLinkSection.styled';
import { spacing } from '@/global/style/variables';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';
import { Fragment } from 'react';
import classNames from 'classnames';

type FooterLinkSectionProps = {
  footerLinks: FooterLinkItem[];
};

const FooterLinkSection = (props: FooterLinkSectionProps) => {
  const { footerLinks } = props;

  return (
    <SectionContent>
      {footerLinks.map(({ content }, index) => {
        let itemContent = null;
        if (content.$type === FooterSectionItemType.RichTextElement) {
          const dataTestId = `footer-rich-text-${content.label || content.text}`;
          itemContent = <Typography data-testid={dataTestId} text={<HtmlFromCMS html={content.text} />} />;
        }

        if (content.$type === FooterSectionItemType.Button) {
          const dataTestId = `footer-button-${content.buttonText}`;
          itemContent = (
            <SectionItem>
              <Divider marginY={spacing.x05} />
              <Button
                href={content.buttonLink.url}
                label={content.buttonText}
                data-testid={dataTestId}
                size={ButtonSize.Auto}
                asLink
              />
            </SectionItem>
          );
        }

        if (content.$type === FooterSectionItemType.LinkElement) {
          const isExternalLink = content.link.target === '_blank';
          const ariaLabel = isExternalLink ? `${content.link.name}, öppnas i en ny flik` : content.link.name;
          const dataTestId = `footer-link-${content.link.name}`;
          itemContent = (
            <SectionItem>
              <StyledLink
                href={content.link.url}
                target={content.link.target}
                data-testid={dataTestId}
                className={classNames({ 'external-link': isExternalLink })}
                aria-label={ariaLabel}
              >
                {content.link.name}
              </StyledLink>
            </SectionItem>
          );
        }

        return (
          <Fragment key={`item-${index.toString()}`}>
            {itemContent}
            {index !== footerLinks.length - 1 && <Divider marginY={spacing.x15} />}
          </Fragment>
        );
      })}
    </SectionContent>
  );
};

export default FooterLinkSection;
