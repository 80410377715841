import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { shadows, spacing } from '@/global/style/variables';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: white;
  z-index: 1;
  padding-left: ${spacing.x2};
  padding-right: ${spacing.x2};
  box-shadow: ${shadows.tabbar};
  ${above.xs`
    padding-left: ${spacing.x3};
    padding-right: ${spacing.x3};
  `}
  transition: transform .3s ease;
  transform: translateY(0);
`;

export const Inner = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  max-width: 375px;
  margin: 0 auto;
`;
