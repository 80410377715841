import styled, { css, keyframes } from 'styled-components';

import { ContentWidth, above } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';

const slideDown = keyframes`
  from {
      top: -100px;
    }
    to {
      top: 72px;
    }
`;

const stickyHeader = css`
  position: fixed;
  top: 72px;
  animation: ${slideDown} 0.2s;
  border-top: 1px solid ${Color.Gray2};
`;

const regularHeader = css`
  position: relative;
`;

export const HeaderSecondaryContainer = styled.div<{ $sticky: boolean }>`
  background-color: ${Color.White};
  border-bottom: 1px solid ${Color.Gray2};
  display: grid;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 949;
  ${({ $sticky }) => ($sticky ? stickyHeader : regularHeader)}
`;

export const HeaderSecondaryPlaceholder = styled.div<{ $show: boolean }>`
  height: 50px;
  background-color: ${Color.White};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`;

export const SecondaryLinkNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: ${ContentWidth.lg};
  background-color: ${Color.White};
  overflow: auto;
  padding: 0 ${spacing.x2};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${above.sm`
    margin: 0 auto;
    justify-content: center;
    padding: 0;
  `}
`;
export const SecondaryLinkContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: ${ContentWidth.lg};
  background-color: ${Color.White};
  overflow: auto;
  padding: 0 ${spacing.x2};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${above.sm`
    margin: 0 auto;
    justify-content: center;
    padding: 0;
  `}
`;

const ScrollBase = css<{ $show: boolean }>`
  display: grid;
  align-items: center;
  position: absolute;
  top: 2px;
  padding: 0 ${spacing.x15};
  background-color: rgba(255, 255, 255, 0.75);
  height: calc(100% - 3px);
  cursor: pointer;
  z-index: 900;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
  border: 2px solid ${Color.Transparent};
  outline: none;

  &:focus-visible {
    outline: 2px solid ${Color.Ocean};
  }

  ${({ $show }) =>
    $show
      ? css`
          opacity: 1;
          pointer-events: auto;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `};
`;

export const ScrollLeft = styled.button<{ $show: boolean }>`
  border-right: 1px solid ${Color.Gray2};
  left: 2px;

  ${ScrollBase};
`;

export const ScrollRight = styled.button<{ $show: boolean }>`
  border-left: 1px solid ${Color.Gray2};
  right: 2px;

  ${ScrollBase};
`;
