'use client';

import { ReactNode } from 'react';
import SessionProvider from '@/app/_lib/providers/SessionProvider';
import ContextProvider, { defaultContext } from '@/global/layouts/ContextProvider';
import { Account, Brand, CmsFeatureFlags, CmsSettings, UserObject } from '@/types';
import CmsProvider from '@/global/layouts/CmsProvider';
import { usePageBrand } from './_lib/hooks/usePageBrand';

type ProviderProps = {
  children: ReactNode;
  cmsSettingsData: CmsSettings;
  cmsFeatureFlags: CmsFeatureFlags;
  account?: Account;
  userObject?: UserObject;
  hasActiveCart: boolean;
  isApp: boolean;
};

const Providers = (props: ProviderProps) => {
  const { children, account, userObject, hasActiveCart, isApp, cmsSettingsData, cmsFeatureFlags } = props;
  const pageBrand = usePageBrand(account);

  return (
    <ContextProvider
      context={{
        ...defaultContext,
        account: account ?? null,
        accounts: userObject?.accounts,
        userId: account?.userId ?? null,
        userEmail: userObject?.email ?? null,
        loginMethod: userObject?.loginMethod ?? null,
        pageBrand: pageBrand || Brand.B2C,
        hasActiveCart: hasActiveCart ?? false,
        roles: userObject?.roles ?? [],
        isApp,
      }}
    >
      <CmsProvider context={{ settings: cmsSettingsData, featureFlags: cmsFeatureFlags?.features }}>
        <SessionProvider session={null}>{children}</SessionProvider>
      </CmsProvider>
    </ContextProvider>
  );
};

export default Providers;
