import Divider from '@/newComponents/Divider/Divider';
import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';

import type { HeaderComplementaryNavigation, HeaderLink } from '@/types';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import { useClickOutside } from '@/global/hooks/useClickOutside';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import useScrollLockBody from '@/global/hooks/useScrollLockBody';
import MainIconLink from '@/global/layouts/parts/Header/HeaderMain/MainIconLink/MainIconLink';
import { breakpoints } from '@/global/style/breakpoint';
import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import {
  HamburgerActiveCartIndicator,
  HamburgerMenuContainer,
  HamburgerMenuOverlay,
  HamburgerMenuWrapper,
  IconContainer,
  MainIconLinkContainer,
  ShoppingCartActiveCartIndicator,
  StyledLinkLookingButton,
  StyledLinkLookingIconButton,
} from './HamburgerMenu.styled';

type HamburgerMenuProps = {
  mainLinks: HeaderLink[];
  mainIcons: HeaderComplementaryNavigation[];
  setHamburgerMenuIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ignoreOutsideClickClassName = 'ignore-outside-click-hamburger';

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const { mainLinks, mainIcons, setHamburgerMenuIsOpen } = props;
  const [isOpen, _setIsOpen] = useState<boolean>(false);
  const setIsOpen = (_isOpen: boolean) => {
    _setIsOpen(_isOpen);
    setHamburgerMenuIsOpen(_isOpen);
  };

  const { hasActiveCart } = useAppContext();
  const menuOverlayRef = useClickOutside<HTMLDivElement>(() => setIsOpen(false), ignoreOutsideClickClassName);
  const isMobileSm = useMatchMedia(`(max-width:${breakpoints.xs})`);
  const [blockScroll, allowScroll] = useScrollLockBody();
  const shouldShowActiveCartIndicator = (mainIcon: HeaderComplementaryNavigation) =>
    (mainIcon.type === 'cart-b2c' || mainIcon.type === 'cart-b2b') && hasActiveCart;

  useEffect(() => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [allowScroll, blockScroll, isOpen]);

  return (
    <HamburgerMenuWrapper>
      <MainIconLinkContainer>
        {hasActiveCart && isMobileSm && !isOpen && <HamburgerActiveCartIndicator />}
        <MainIconLink
          data-testid="hamburger-menu"
          active={isOpen}
          isOpen={isOpen}
          iconLink={{
            link: {
              url: '*',
              name: '',
              target: null,
            },
            icon: 'runner',
            text: 'Meny',
            type: 'link',
          }}
          customIcon="animated-menu"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          ignoreOutsideClickClassName={ignoreOutsideClickClassName}
          ignoreOutsideClick
        />
      </MainIconLinkContainer>

      {isOpen && (
        <HamburgerMenuOverlay ref={menuOverlayRef} className={ignoreOutsideClickClassName}>
          <HamburgerMenuContainer>
            <Divider marginY={0} />
            {mainLinks.map((mainLink) => (
              <Fragment key={mainLink.link.url}>
                <StyledLinkLookingButton
                  data-testid={`hamburger-link${mainLink.link.url}`}
                  text={<Typography text={mainLink.text} fontWeight={FontWeight.SemiBold} />}
                  onClick={() => {
                    setIsOpen(false);
                    window.location.href = mainLink.link.url;
                  }}
                />
                <Divider color={Color.Gray2} marginY={0} />
              </Fragment>
            ))}

            {mainIcons.map((mainIcon) => (
              <Fragment key={mainIcon.icon}>
                <IconContainer>
                  {shouldShowActiveCartIndicator(mainIcon) && <ShoppingCartActiveCartIndicator />}
                  <StyledLinkLookingIconButton
                    text={mainIcon.text}
                    onClick={() => {
                      window.location.href = mainIcon.link.url;
                    }}
                    iconKey={mainIcon.icon as IconKey}
                    leftIcon
                    data-testid={`hamburger-link${mainIcon.link.url}`}
                  />
                </IconContainer>
                <Divider color={Color.Gray2} marginY={0} />
              </Fragment>
            ))}
          </HamburgerMenuContainer>
        </HamburgerMenuOverlay>
      )}
    </HamburgerMenuWrapper>
  );
};

export default HamburgerMenu;
