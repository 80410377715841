import { ColorStyle, StyledButtonCss } from '@/newComponents/Button/Button.styled';
import { inputStyle, inputWrapperStyle } from '@/newComponents/Input/Input.styled';

import { css } from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

const ContainerCss = css`
  margin-top: ${spacing.x05};
  display: flex;
  align-items: center;
  background: ${(props) => props.$bgColor ?? ''};
  color: ${(props) => props.$color ?? ''};
  padding: ${spacing.x1};
  border-radius: ${BorderRadius.Small};
`;

const spanCss = css`
  display: block;
  font-size: 1.4rem;
  font-weight: ${FontWeight.Regular};
  width: 100%;
  margin-top: ${spacing.x05};
`;

const adyen = css`
  .adyen-checkout__card-input {
    &&& {
      display: flex;
      flex-direction: column;
      border-radius: ${BorderRadius.Medium};
      align-items: center;
    }
  }

  .adyen-checkout__label {
    &&& {
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: left;
      margin-bottom: ${spacing.x1};
      font-weight: ${FontWeight.SemiBold};

      &[data-has-focus='true'] {
        & > div {
          border-bottom-width: 3px;
        }
      }
    }
  }

  .adyen-checkout__label__text {
    &&& {
      font-size: 1.6rem;
      font-weight: ${FontWeight.SemiBold};
      line-height: 2.4rem;
      padding-bottom: 0;

      &--error {
        color: black;
      }

      .adyen-checkout__label--focused & {
        color: black;
      }
    }
  }

  .adyen-checkout__card__holderName {
    margin-top: ${spacing.x4};
  }

  .adyen-checkout__input-wrapper {
    &&& {
      ${inputWrapperStyle}

      .adyen-checkout__label--focused & {
        border-bottom-color: ${Color.Blue};
      }

      .adyen-checkout__field--valid & {
        border-bottom-color: ${Color.Forest};
      }

      .adyen-checkout__field--error & {
        border-bottom-color: ${Color.Hallon1};
      }
    }
  }

  .adyen-checkout__input {
    &&& {
      ${inputStyle}

      &:disabled {
        background-color: ${Color.Gray2};
      }

      &--focus {
        &:hover,
        &:focus:hover,
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
        border: none;
        box-shadow: none;
      }
    }
  }

  .adyen-checkout-form-instruction {
    display: none;
  }

  .adyen-checkout__error-text {
    & {
      ${ContainerCss}
      ${spanCss}

      background: ${Color.Rose};
      color: ${Color.Hallon1};
      display: block;
      border-radius: 0;
      position: relative;
      top: -${spacing.x05};

      &:empty {
        display: none;
      }
    }
  }

  .adyen-checkout__button {
    &&& {
      margin: ${spacing.x4} auto 0 auto;
      ${StyledButtonCss}

      &:hover {
        box-shadow: none;
      }
    }

    ${ColorStyle.Primary}
  }

  .adyen-checkout__button.adyen-checkout__button--loading {
    background-color: ${Color.Hallon4};
  }

  .adyen-checkout__button__icon {
    &&& {
      display: none;
    }
  }
`;

export default adyen;
