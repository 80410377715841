'use client';

import type { Session } from 'next-auth';
import { SessionProvider as AuthSessionProvider } from 'next-auth/react';

const SessionProvider = ({ children, session }: { children: React.ReactNode; session: Session | null }) => {
  return (
    <AuthSessionProvider session={session} refetchInterval={10 * 60} refetchOnWindowFocus={false}>
      {children}
    </AuthSessionProvider>
  );
};

export default SessionProvider;
