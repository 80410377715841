import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, shadows, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

export const Container = styled.div`
  position: relative;
  display: block;
  width: calc(100% - ${spacing.x2});
  color: ${Color.MineShaft};

  a {
    color: ${Color.MineShaft};
  }

  // Bigger bubble, attached to container
  &:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${Color.White};
    position: absolute;
    top: -12px;
    left: 40px;
  }

  // Smaller bubble, attached to container
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${Color.White};
    position: absolute;
    top: -24px;
    left: 40px;
  }
`;

export const BackgroundWrapper = styled.div`
  display: block;
  width: 100%;
  padding: ${spacing.x2};
  margin-top: ${spacing.x3};

  border-radius: ${BorderRadius.Large};
  background: ${Color.White};
  min-height: 100px;
  box-shadow: ${shadows.menu};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.x1} 0;
  width: 100%;
  height: 100%;
  border-radius: ${BorderRadius.Large};

  & > h4 {
    margin-top: 0;
    flex: 0 0 auto;
  }

  & > button {
    width: 100%;
    flex: 0 0 auto;
  }

  ${above.md`
    max-height: calc(100vh - 110px);
  `}
`;

export const AccountSlider = styled.div`
  position: relative;
  flex: 1 1 auto;

  margin-bottom: ${spacing.x3};
  margin-top: ${spacing.x1};

  width: auto;
  white-space: nowrap;
  transition: transform 0.3s ease-out, height 0.3s ease-out;
  transform: translate(0);

  &[data-is-switch-active='true'] {
    transform: translate(-100%);
  }
`;

export const AccountSliderItem = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  min-height: 24px;
  max-height: 50vh;
  overflow-y: scroll;
`;

export const AccountButtonWrapper = styled.div<{ hasButtons?: false }>`
  overflow-y: scroll;
  height: 100%;

  ${({ hasButtons }) =>
    hasButtons === false
      ? `
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''}

  & > span {
    font-weight: ${FontWeight.SemiBold};
  }
`;

export const BottomButtonWrapper = styled.div`
  display: block;
  margin-top: ${spacing.x2};
`;

export const ChangeAccountButton = styled.button`
  position: relative;
  width: 100%;
  color: ${Color.MineShaft};
  padding: ${spacing.x3} ${spacing.x2};
  font-weight: ${FontWeight.SemiBold};
  text-align: left;
  border-top: 1px solid ${Color.Gray2};
  border-bottom: 1px solid ${Color.Gray2};
  margin-top: ${spacing.x3};
  margin-left: -${spacing.x2};
  -webkit-tap-highlight-color: ${Color.Transparent};

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`;
