import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

export const basePaddingY = css`
  padding-top: ${spacing.x2};
  padding-bottom: ${spacing.x2};

  ${above.md`
    padding-top: ${spacing.x3};
    padding-bottom: ${spacing.x3};
  `}
`;

export const basePaddingX = css`
  padding-right: ${spacing.x2};
  padding-left: ${spacing.x2};

  ${above.md`
    padding-right: ${spacing.x4};
    padding-left: ${spacing.x4};
  `}
`;

export const HeaderContainer = styled.div<{ $backgroundColor: HallonColor }>`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const SkipNavContent = styled.a`
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;

  &:focus {
    border: none;
    outline: none;
    position: static;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${Color.Gray3};
  }

  span,
  p {
    padding-top: ${spacing.x25};
    padding-bottom: ${spacing.x25};
  }
`;
