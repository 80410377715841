'use client';

import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';

import AppTabBarButton from '@/components/AppTabBar/AppTabBarButton';

import { Container, Inner } from './AppTabBar.styled';

const AppSections = {
  NONE: 'none',
  ABONNEMANG: 'abonnemang',
  BETALNINGAR: 'betalningar',
  KONTOINSTALLNINGAR: 'kontoinstallningar',
};

const subscriptionUrl = '/mina-sidor/abonnemang';
const paymentsUrl = '/mina-sidor/betalningar';
const accountSettingsUrl = '/mina-sidor/kontoinstallningar';

const CardSvg = () => <SvgIcon icon="card" />;
const DetailsSvg = () => <SvgIcon icon="details" />;
const PhoneSvg = () => <SvgIcon icon="mobile" />;

const AppTabBar = () => {
  const router = useRouter();
  const pathname = usePathname();

  const currentSection = useMemo(() => {
    const path = pathname ?? '/';

    switch (true) {
      case path === subscriptionUrl:
      case path.match(/\/(mina-sidor)\/(\d*)$/g) !== null:
      case path.match(/\/(mina-sidor)\/(\d*)\/[\S]*$/g) !== null:
        return AppSections.ABONNEMANG;
      case path === paymentsUrl:
      case path.match(/\/(mina-sidor)\/(betalningar)\/(\d*)$/g) !== null:
        return AppSections.BETALNINGAR;
      case path === accountSettingsUrl:
        return AppSections.KONTOINSTALLNINGAR;
      default:
        return AppSections.NONE;
    }
  }, [pathname]);

  return (
    <Container>
      <Inner>
        <AppTabBarButton
          dataTestId="subscription-tab-button"
          onClick={() => {
            router.push(subscriptionUrl);
          }}
          IconSvg={PhoneSvg}
          label="Abonnemang"
          active={currentSection === AppSections.ABONNEMANG}
        />
        <AppTabBarButton
          dataTestId="payments-tab-button"
          onClick={() => {
            router.push(paymentsUrl);
          }}
          IconSvg={CardSvg}
          label="Betalningar"
          active={currentSection === AppSections.BETALNINGAR}
        />
        <AppTabBarButton
          dataTestId="account-settings-tab-button"
          onClick={() => {
            router.push(accountSettingsUrl);
          }}
          IconSvg={DetailsSvg}
          label="Konto"
          active={currentSection === AppSections.KONTOINSTALLNINGAR}
        />
      </Inner>
    </Container>
  );
};

export default AppTabBar;
