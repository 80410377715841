import Radio, { RadioProps } from '@/newComponents/Radio/Radio';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import { RadioCardContainer, RadioCardOverrideBorder } from '@/components/RadioCard/RadioCard.styled';

type ExtendedRadioCardProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & RadioProps;

const RadioCard = (props: ExtendedRadioCardProps) => {
  const { disabled, checked, label, children, ...rest } = props;

  return (
    <RadioCardContainer $checked={checked} $disabled={disabled}>
      <RadioCardOverrideBorder className="radio-card-override-border" />
      <Radio checked={checked} disabled={disabled} aria-label={label?.toString()} hoverVisible={false} {...rest} />
      {children}
    </RadioCardContainer>
  );
};

export default RadioCard;
