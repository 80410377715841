import styled from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const SecondaryLinkContainer = styled.li`
  display: grid;
  margin-right: ${spacing.x2};
  &:last-of-type {
    margin-right: 0px;
  }
`;

export const SecondaryLinkItem = styled.a<{ $active: boolean }>`
  margin-top: ${spacing.x1};
  margin-bottom: ${spacing.x05};
  padding: ${spacing.x05} ${spacing.x1} 2px ${spacing.x1};
  border-radius: ${BorderRadius.Small};
  border: 2px solid ${Color.Transparent};
  outline: none;
  position: relative;

  &:hover {
    background-color: ${Color.MineShaftHover};
  }

  &:active {
    background-color: ${Color.MineShaftActive};
  }

  &:focus-visible {
    border-color: ${Color.Ocean};
  }

  span {
    white-space: nowrap;
  }
`;

export const ActiveIndicator = styled.div<{ $active: boolean }>`
  width: calc(100% - ${spacing.x2});
  margin: 0 auto;
  border: 2px solid ${Color.Transparent};
  position: relative;
  top: 0px;
  z-index: 900;
  ${({ $active }) => $active && `border-color: ${Color.Blue};`}
`;
