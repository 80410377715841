'use client';

import Button from '@/newComponents/Button/Button';
import Card from '@/newComponents/Card/Card';
import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { CmsSettings } from '@/types';

import { spacing } from '@/global/style/variables';

type MaintenanceProps = {
  settings: CmsSettings;
};

const Maintenance = (props: MaintenanceProps) => {
  const { settings } = props;

  return (
    <Card data-testid="maintenance-card">
      <SvgIcon icon="tool" />
      <Typography text={settings.maintenanceHeading} type={TypographyType.HeadingS} align="center" />
      <Divider marginY={spacing.x1} />
      <Typography text={settings.maintenancePageText} align="center" />

      <Divider marginY={spacing.x2} />

      <Button
        label={settings.maintenanceButtonText}
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
        }}
        data-testid="maintenance-mode-try-again-button"
        centered
      />
    </Card>
  );
};

export default Maintenance;
