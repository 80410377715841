import { css } from 'styled-components';

import { FontWeight } from '@/constants';
import { Color, spacing } from './variables';

const ExternalLinkStyles = css`
  a {
    color: ${Color.Blue};
    text-decoration: none;
    font-weight: ${FontWeight.SemiBold};
  }
  .external-link {
    position: relative;
    &:after {
      content: '';
      margin-left: ${spacing.x05};
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23004496' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-external-link'><path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'></path><polyline points='15 3 21 3 21 9'></polyline><line x1='10' y1='14' x2='21' y2='3'></line></svg>");
      color: inherit;
      margin-top: -6px;
      vertical-align: middle;
    }
    &.small:after {
      width: 1.3rem;
      height: 1.3rem;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='13' height='13' viewBox='0 0 24 24' fill='none' stroke='%23004496' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-external-link'><path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'></path><polyline points='15 3 21 3 21 9'></polyline><line x1='10' y1='14' x2='21' y2='3'></line></svg>");
    }
  }
`;

export default ExternalLinkStyles;
