import { CmsProductType } from '@/types';

import { AccordionSectionType } from '@/pagewrappers/ChangeSubscription/ChangeSubscription.types';

export const accordionSectionTypeToCmsProductTypeMapper = (
  accordionSectionType: AccordionSectionType
): CmsProductType => {
  switch (accordionSectionType) {
    case AccordionSectionType.NonContract:
      return CmsProductType.NonContract;
    case AccordionSectionType.ExtraUser:
      return CmsProductType.ExtraUser;
    case AccordionSectionType.Contract:
    default:
      return CmsProductType.Contract;
  }
};
