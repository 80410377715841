import Spinner from '@/newComponents/Spinner/Spinner';

import styled from 'styled-components';

import { spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

export const Button = styled.button<{ $marked: boolean; $isLoading: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  font-weight: ${FontWeight.SemiBold};
  padding: ${spacing.x15} 0;

  transition: all 0.3s ease;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    transition: all 0.3s ease;
    opacity: 0;
  }

  ${(props) =>
    props.$marked &&
    `
      svg {
        opacity: 1;
        left: 0;
      }
      padding-left: ${spacing.x25};
    `};

  ${(props) =>
    props.$isLoading &&
    `
      padding-left: ${spacing.x25};
    `};
`;

export const ButtonInnerContent = styled.div`
  display: flex;
  align-items: center;
  height: ${spacing.x3};
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
`;
