'use client';

import { Brand, FooterMinimal, FooterSection } from '@/types';

import FooterBottom from '@/global/layouts/parts/Footer/FooterBottom/FooterBottom';
import FooterLinks from '@/global/layouts/parts/Footer/FooterLinks/FooterLinks';
import styles from './Footer.module.css';

type FooterProps = {
  mainCmsData?: FooterSection[] | false;
  miniCmsData: FooterMinimal;
  pageBrand: Brand;
  hasBottomOverlay?: boolean;
};

const Footer = (props: FooterProps) => {
  const { mainCmsData, miniCmsData, pageBrand, hasBottomOverlay = false } = props;

  return (
    <footer className={styles.footer}>
      {mainCmsData && <FooterLinks footerSections={mainCmsData} />}

      <FooterBottom minimalFooterData={miniCmsData} brandProfile={pageBrand} hasBottomOverlay={hasBottomOverlay} />
    </footer>
  );
};

export default Footer;
