import { Label, RadioInput, RadioInputContainer } from '@/newComponents/Radio/Radio.styled';
import { InputHTMLAttributes, ReactNode } from 'react';

export type RadioProps = {
  'data-testid': string;
  label?: ReactNode;
  focusVisible?: boolean;
  hoverVisible?: boolean;
};

type ExtendedRadioProps = InputHTMLAttributes<HTMLInputElement> & RadioProps;

const Radio = ({
  'data-testid': dataTestId,
  label,
  disabled = false,
  name,
  checked,
  onChange,
  focusVisible = true,
  hoverVisible = true,
  ...props
}: ExtendedRadioProps) => {
  return (
    <>
      <RadioInputContainer $disabled={Boolean(disabled)} $hoverVisible={hoverVisible}>
        <RadioInput
          data-testid={dataTestId}
          type="radio"
          checked={checked}
          onChange={onChange}
          disabled={Boolean(disabled)}
          name={name}
          id={name}
          $focusVisible={focusVisible}
          {...props}
        />
      </RadioInputContainer>

      <Label htmlFor={name} onClick={(e) => disabled && e.preventDefault()} $disabled={disabled}>
        {label}
      </Label>
    </>
  );
};

export default Radio;
