import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

const ScrollState = {
  PAUSED: 'paused',
  UP: 'up',
  DOWN: 'down',
};

const ScrollPosition = {
  TOP: 'top',
  NOT_TOP: 'not_top',
};

export { ScrollState, ScrollPosition };

export const useAppScrollPosition = (topOfPageBreakpoint = 0) => {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [scrollPosition, setScrollPosition] = useState(() => {
    if (typeof window === 'undefined') {
      return ScrollPosition.TOP;
    }
    return window.scrollY <= topOfPageBreakpoint ? ScrollPosition.TOP : ScrollPosition.NOT_TOP;
  });

  const [scrollState, setScrollState] = useState(ScrollState.PAUSED);

  useEffect(() => {
    let lastScrollPosition = 0;
    let ticking: NodeJS.Timeout;
    let top = 0;

    const TOUCH_MIN_DISTANCE = 5;
    const DEBOUNCE_DELAY = 200;
    const MIN_POSITION_ALLWAYS_SHOW = 30;

    const calculateScrollPosition = () => {
      top = window.pageYOffset || document.documentElement.scrollTop;

      if (Math.abs(top - lastScrollPosition) > TOUCH_MIN_DISTANCE) {
        if (top > lastScrollPosition) {
          setScrollState(ScrollState.DOWN);
        } else {
          setScrollState(ScrollState.UP);
        }
      } else if (top <= MIN_POSITION_ALLWAYS_SHOW) {
        setScrollState(ScrollState.UP);
      }

      lastScrollPosition = top;
      // Not on top?
      // If page is locked dont change the current state
      const lockedScrollValue = parseInt(document.body.style.top, 10);
      if (!lockedScrollValue) {
        if (window.scrollY > topOfPageBreakpoint) {
          setScrollPosition(ScrollPosition.NOT_TOP);
        } else {
          // Is on top
          setScrollPosition(ScrollPosition.TOP);
        }
      }
    };

    const handleScrollEvent = () => {
      if (ticking == null) {
        calculateScrollPosition();
      } else {
        ticking = setTimeout(() => clearTimeout(ticking), DEBOUNCE_DELAY);
      }
    };

    let timeoutInterval: NodeJS.Timeout;
    const handleWindowResize = () => {
      clearTimeout(timeoutInterval);
      timeoutInterval = setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 10);
    };

    // Prerender links
    window.addEventListener('scroll', handleScrollEvent);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Remove listener when component will unmount
      window.removeEventListener('scroll', handleScrollEvent);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [topOfPageBreakpoint]);

  return [windowWidth, scrollPosition, scrollState];
};

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
  PAUSED = 'paused',
}

/* NOTE! this hook may cause multiple re-renders of components when scrolling */
export const useScrollPosition = (topOfPageBreakpoint = 0, debounceTimeout = 50) => {
  const [position, setPosition] = useState(0);
  const [direction, setDirection] = useState(ScrollDirection.PAUSED);
  const [isTop, setIsTop] = useState<boolean>(() => {
    if (typeof window === 'undefined') return true;
    return window.scrollY <= topOfPageBreakpoint;
  });

  useEffect(() => {
    let lastScrollPosition = 0;

    const handleScrollEventDebounce = debounce(() => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      setPosition(st);

      if (st > lastScrollPosition) {
        setDirection(ScrollDirection.DOWN);
      } else {
        setDirection(ScrollDirection.UP);
      }
      lastScrollPosition = st <= 0 ? 0 : st;

      // Not on top? If page is locked dont change the current state
      const lockedScrollValue = parseInt(document.body.style.top, 10);
      if (!lockedScrollValue) {
        if (window.scrollY > topOfPageBreakpoint) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      }
    }, debounceTimeout);

    window.addEventListener('scroll', handleScrollEventDebounce);
    window.addEventListener('resize', handleScrollEventDebounce);

    return () => {
      // Remove listener when component will unmount
      window.removeEventListener('scroll', handleScrollEventDebounce);
      window.removeEventListener('resize', handleScrollEventDebounce);
    };
  }, [topOfPageBreakpoint, debounceTimeout]);

  return [position, isTop, direction];
};
