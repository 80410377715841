import Typography from '@/newComponents/Typography/Typography';

import { ActiveIndicator, SecondaryLinkContainer, SecondaryLinkItem } from './SecondaryLink.styled';

type SecondaryLinkProps = {
  text: string;
  link: string;
  active: boolean;
  className?: string;
};

const SecondaryLink = (props: SecondaryLinkProps) => {
  const { text, link, active, className = '' } = props;

  return (
    <SecondaryLinkContainer className={className} data-testid={`secondary-link-${text}`}>
      <SecondaryLinkItem href={link} $active={active}>
        <Typography text={text} />
      </SecondaryLinkItem>

      <ActiveIndicator $active={active} />
    </SecondaryLinkContainer>
  );
};

export default SecondaryLink;
