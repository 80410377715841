import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';

import LinkLookingButton from '@/components/LinkLookingButton/LinkLookingButton';

export const HamburgerMenuWrapper = styled.div`
  display: block;
  margin-left: ${spacing.x2};

  ${above.lg`
    display: none;
  `}
`;

export const MainIconLinkContainer = styled.div`
  position: relative;
`;

export const HamburgerMenuOverlay = styled.div`
  position: absolute;
  z-index: 100;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${Color.White};
  top: 73px;

  ${above.md`
    top: 81px;
  `}
`;

export const HamburgerMenuContainer = styled.nav`
  width: 100%;
  background-color: ${Color.White};
`;

export const StyledLinkLookingButton = styled(LinkLookingButton)`
  width: 100%;
  margin: 0;
  padding: ${spacing.x15} ${spacing.x2};
  text-decoration: none;
`;

export const IconContainer = styled.div`
  position: relative;
`;

export const StyledLinkLookingIconButton = styled(LinkLookingButton)`
  width: 100%;
  color: ${Color.MineShaft};
  margin: 0;
  display: flex;
  padding: ${spacing.x15} ${spacing.x2};
  text-decoration: none;
  text-align: left;

  span {
    position: relative;
    top: -2px;
  }
`;

export const HamburgerActiveCartIndicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: ${Color.Hallon1};
  border: 1px solid ${Color.White};
  border-radius: 100%;
  z-index: 5;
  top: 10px;
  left: 27px; // to align the circle to the Hamburger menu icon
`;

export const ShoppingCartActiveCartIndicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: ${Color.Hallon1};
  border: 1px solid ${Color.White};
  border-radius: 100%;
  z-index: 5;
  top: 11px;
  left: 30px; // to align the circle to the shoppingBag icon
`;
