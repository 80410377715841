import styled from 'styled-components';

import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color } from '@/global/style/variables';

export const Container = styled.div<{ $backgroundColor: Color }>`
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const ContentBody = styled.div`
  ${GridLargeWrapper}
`;
