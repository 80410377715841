import { useEffect, useRef } from 'react';

import { Wrapper } from '@/components/DropDownMenuAnimationWrapper/DropDownMenuAnimationWrapper.styled';

type DropDownMenuAnimationWrapperProps = {
  className?: string;
  visible?: boolean;
  setVisible: (arg: boolean) => void;
  children: JSX.Element;
};

const DropDownMenuAnimationWrapper = (props: DropDownMenuAnimationWrapperProps) => {
  const { className, visible = false, setVisible, children } = props;
  const wrapperRef = useRef(null);

  useEffect(() => {
    const composedPath = (el: HTMLElement | null) => {
      const path = [];
      while (el) {
        path.push(el);
        if (el.tagName === 'HTML') {
          path.push(document);
          path.push(window);
          break;
        }
        // eslint-disable-next-line no-param-reassign
        el = el.parentElement;
      }
      return path;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (e: any) => {
      if (visible) {
        const path = e.path || (e.composedPath && e.composedPath()) || composedPath(e.target);

        let isInside = false;
        for (let i = 0, len = path.length; i < len; i += 1) {
          if (path[i] === document.body) break;
          if (
            path[i] === wrapperRef.current ||
            path[i].classList.contains('menu-button') ||
            path[i].classList.contains('mobile-menu-button')
          ) {
            isInside = true;
            break;
          }
        }
        if (!isInside) {
          setVisible(false);
        }

        // https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [visible, wrapperRef, setVisible]);

  return (
    <Wrapper ref={wrapperRef} className={className} data-state={visible ? 'visible' : 'hidden'}>
      {children}
    </Wrapper>
  );
};

export default DropDownMenuAnimationWrapper;
