import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

export const AccordionListItem = styled.div<{
  $size: 'small' | 'medium' | 'large';
  $disabled: boolean;
  $open: boolean;
  $borderRadius?: boolean;
}>`
  width: 100%;
  overflow: hidden;
  &:not(:last-child) {
    border-bottom: 1px solid ${Color.Gray2};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${Color.DarkGray2};
      pointer-events: none;
    `}

  ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      &:first-child > button {
        border-top-left-radius: ${BorderRadius.Large};
        border-top-right-radius: ${BorderRadius.Large};
      }

      &:last-child > button:not([aria-expanded='true']) {
        border-bottom-left-radius: ${BorderRadius.Large};
        border-bottom-right-radius: ${BorderRadius.Large};
      }
    `}
`;

export const AccordionTitleContainer = styled.button<{
  $size: 'small' | 'medium' | 'large';
  $backgroundColor?: HallonColor;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || Color.White};
  width: 100%;
  padding: ${({ $size }) => ($size === 'small' ? `${spacing.x2}` : `${spacing.x3}`)};
  text-align: left;
  transition: background-color ease-out 0.2s;
  outline: none;
  border: 2px solid ${Color.Transparent};

  ${({ $size }) =>
    $size === 'large' &&
    css`
      padding: ${spacing.x3} ${spacing.x2};

      ${above.sm`
        padding: ${spacing.x3};
      `}
    `};

  &:focus-visible {
    border: 2px solid ${Color.Ocean};
  }

  &:hover {
    background-color: ${Color.MineShaftHover};
  }

  &:active {
    background-color: ${Color.MineShaftActive};
  }
`;

export const TitleWrapper = styled.div<{ $hasIcon: boolean }>`
  display: grid;
  grid-template-columns: ${({ $hasIcon }) => ($hasIcon ? 'min-content 1fr min-content' : '1fr min-content')};
  align-items: center;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  margin-top: ${spacing.x05};
`;

export const StyledSvgIcon = styled(SvgIcon)`
  margin-right: ${spacing.x15};
`;

export const AccordionBody = styled(motion.div)<{ $backgroundColor?: HallonColor }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || Color.Gray3};
`;

// We need the innerBody to get the animation height: auto to play along
export const AccordionBodyInner = styled.div`
  padding: ${spacing.x2} ${spacing.x2} ${spacing.x3} ${spacing.x2};

  ${above.sm`
    padding: ${spacing.x3} ${spacing.x3} ${spacing.x4} ${spacing.x3};
  `}
`;
