'use client';

import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';

import { FooterSection } from '@/types';

import FooterLinkSection from '@/global/layouts/parts/Footer/FooterLinks/FooterLinkSection/FooterLinkSection';
import {
  DesktopFooter,
  FooterLinksContainer,
  MobileFooter,
  SectionContainer,
  SectionHeader,
} from '@/global/layouts/parts/Footer/FooterLinks/FooterLinks.styled';
import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import Accordion from '@/components/Accordion/Accordion';

type FooterLinksProps = {
  footerSections: FooterSection[];
};

const FooterLinks = (props: FooterLinksProps) => {
  const { footerSections } = props;

  return (
    <FooterLinksContainer>
      <>
        <DesktopFooter>
          {footerSections.map(({ content }) => (
            <SectionContainer key={content.headline}>
              <SectionHeader>
                <SvgIcon icon={content.icon} size="small" />
                <Typography text={content.headline} fontWeight={FontWeight.SemiBold} color={Color.Hallon1} />
              </SectionHeader>
              <FooterLinkSection footerLinks={content.footerItems} />
            </SectionContainer>
          ))}
        </DesktopFooter>

        <MobileFooter>
          {footerSections.map(({ content }) => (
            <Accordion
              key={`mobile-footer-links-${content.headline}`}
              borderRadius={false}
              data-testid={`mobile-footer-links-${content.headline}`}
              title={content.headline}
              titleIcon={content.icon}
              size="medium"
              openStateIndicator="icon"
              color={Color.Hallon1}
            >
              <FooterLinkSection footerLinks={content.footerItems} />
            </Accordion>
          ))}
        </MobileFooter>
      </>
    </FooterLinksContainer>
  );
};

export default FooterLinks;
