import styled from 'styled-components';

import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color, spacing } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

export const HeaderBrandContainer = styled.nav<{ $backgroundColor: HallonColor }>`
  ${GridLargeWrapper};
  display: grid;
  grid-template-columns: repeat(2, min-content);
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const BrandItem = styled.a<{ $selected: boolean }>`
  padding: ${spacing.x05} ${spacing.x2};
  background-color: ${({ $selected }) => ($selected ? Color.White : Color.Transparent)};
  border: 2px solid ${Color.Transparent};

  &:focus-visible {
    border-color: ${Color.Ocean};
    outline: none;
  }

  &:hover {
    border-color: ${Color.Hallon1};
    background-color: ${Color.Hallon1Hover};
  }

  &:active {
    border-color: ${Color.Hallon1};
    background-color: ${Color.Hallon1Active};
  }
`;
