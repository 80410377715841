const shouldShowNotification = (pagesToShowNotification: string[], urlPathName: string) => {
  if (!pagesToShowNotification) {
    return false;
  }

  // show on the whole site if exact match of * on root
  if (pagesToShowNotification.find((page) => page.includes('*') && page.length === 1)) {
    return true;
  }

  const isStartPage = pagesToShowNotification[0].includes('/') && pagesToShowNotification[0].length === 1;

  if (isStartPage) {
    return urlPathName === '/';
  }

  const showOnSubPage = pagesToShowNotification.find((pageFilter) => {
    if (pageFilter.includes('/')) {
      const pathArray = pageFilter.split('/*');

      // if exact match of /*
      if (pathArray.length === 0) {
        return true;
      }

      if (pathArray.length > 1 && pathArray[pathArray.length - 1] !== '') {
        return urlPathName.includes(pathArray[pathArray.length - 1]);
      }

      return urlPathName.includes(pathArray[0]);
    }
    return false;
  });
  if (showOnSubPage) {
    return true;
  }
  return pagesToShowNotification.includes(urlPathName);
};

const NotificationHelper = {
  shouldShowNotification,
};

export default NotificationHelper;
