'use client';

import { CmsSettings, FeatureFlag } from '@/types';
import { ReactNode, createContext, useContext } from 'react';

export type HallonCmsContext = {
  settings: CmsSettings;
  featureFlags: FeatureFlag[];
};

export const CmsContext = createContext<HallonCmsContext | undefined>(undefined);

type CmsProviderProps = {
  children: ReactNode;
  context: HallonCmsContext;
};

const CmsProvider = (props: CmsProviderProps) => {
  return <CmsContext.Provider value={props.context}>{props.children}</CmsContext.Provider>;
};

export const useCmsContext = <T extends Partial<HallonCmsContext>>(): HallonCmsContext & T => {
  const context = useContext(CmsContext);
  return context as HallonCmsContext & T;
};

CmsProvider.displayName = 'CmsProvider';

export default CmsProvider;
