import { CmsProductType, Product } from '@/types';

export enum CustomerAndProductCombo {
  B2cVoice = 'accordionProductSectionsB2Cvoice',
  B2cIsp = 'accordionProductSectionsB2Cisp',
  B2bVoice = 'accordionProductSectionsB2Bvoice',
  B2bIsp = 'accordionProductSectionsB2Bisp',
}

// types for simplified cms data
export type FlatOffer = {
  id: string;
  name: string;
  ratorPackageId: string;
  offerDuration: string;
  offerPrice: string;
};

export type FlatOfferList = {
  name: string;
  offers: FlatOffer[];
};

export enum AccordionSectionType {
  Contract = 'Contract',
  NonContract = 'NonContract',
  ExtraUser = 'ExtraUser',
}

export type FlatAccordionSection = {
  productType: CmsProductType;
  header: string;
  type: AccordionSectionType;
  usps: string[];
  changeToSectionButtonText: string;
  changeWithinSectionButtonText: string;
  downgradeWarningKey: string;
  companionHeaderKey: string;
  companionChangeLinkKey: string;
  offerLists: FlatOfferList[];
  orphanNoticeKey?: string;
  orphanNoticeNoParentsKey?: string;
  orphanOffer?: FlatOffer;
};

export type FlatAccordionData = {
  accordionHeader: string;
  accordionB2BvatInformation: string;
  accordionContractCampaignFineprint: string;
  accordionContractCurrentPrice: string;
  accordionNonContractPrice: string;
  accordionProductSectionsB2Bisp: FlatAccordionSection;
  accordionProductSectionsB2Bvoice: FlatAccordionSection;
  accordionProductSectionsB2Cisp: FlatAccordionSection;
  accordionProductSectionsB2Cvoice: FlatAccordionSection;
  errorsList: Record<string, { header: string; body: string }>;
};

export type FlatCmsAccordionData = Record<AccordionSectionType, FlatAccordionSection>;

export type ExtendedProduct = Product & Pick<FlatOffer, 'offerDuration' | 'offerPrice' | 'name'>;

export type FlatCmsError = {
  body: string;
  header: string;
  buttonLink: string;
  buttonText: string;
};
