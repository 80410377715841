import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';

interface HeaderRefContextProps {
  headerRef: React.RefObject<HTMLDivElement>;
}

const HeaderRefContext = createContext<HeaderRefContextProps | undefined>({ headerRef: { current: null } });

type HeaderRefProviderProps = {
  children: ReactNode;
};

const HeaderRefProvider = ({ children }: HeaderRefProviderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const memoizedValues = useMemo(
    () => ({
      headerRef,
    }),
    [headerRef]
  );

  return <HeaderRefContext.Provider value={memoizedValues}>{children}</HeaderRefContext.Provider>;
};

export const useHeaderRef = () => {
  const context = useContext(HeaderRefContext);
  if (!context) {
    throw new Error('useHeaderRef must be used within a HeaderRefProvider');
  }
  return context;
};

export default HeaderRefProvider;
