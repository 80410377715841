'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { setupAppInsights } from '@/global/layouts/AppWrapper';

const ApplicationInsights = () => {
  const pathname = usePathname();

  useEffect(() => {
    const hasApplicationInsights = Boolean(window.appInsights);

    if (!hasApplicationInsights && process.env.APPLICATION_INSIGHT_CONNECTION_STRING) {
      setupAppInsights(pathname ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ApplicationInsights;
