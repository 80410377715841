import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { LinkBaseStyles } from '@/components/LinkLookingButton/LinkLookingButton.styled';

export const SectionContent = styled.ul`
  max-width: 350px;

  ${above.md`
    max-width: 325px;
  `}

  button {
    text-align: left;
  }
`;

export const SectionItem = styled.li`
  list-style: none;
`;

export const StyledLink = styled.a`
  ${LinkBaseStyles};
`;
