import styled, { css } from 'styled-components';

import { Color, shadows } from '@/global/style/variables';
import { FontWeight } from '@/constants';

export const StyledButton = styled.button`
  position: relative;
  display: block;
  outline: none;
  width: 90px;
  -webkit-tap-highlight-color: ${Color.Transparent};
`;

export const ButtonText = styled.span<{ $active?: boolean }>`
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: ${FontWeight.SemiBold};
  font-size: 1.2rem;
  z-index: 2;
  color: ${({ $active }) => ($active ? Color.Hallon1 : Color.MineShaft)};
`;

export const IconWrapperActive = css`
  color: ${Color.Hallon1};
  svg {
    transform: translateY(-10px);
  }
  &:before {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
  &:after {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const IconWrapper = styled.div<{ $active?: boolean }>`
  display: block;
  padding-bottom: 15px;
  color: ${Color.MineShaft};

  svg {
    position: relative;
    z-index: 2;
    overflow: hidden;
    transform: translateY(0);
    transition: transform 0.2s ease;
  }

  &:before {
    content: '';
    display: block;
    width: 90px;
    height: 90px;
    background: ${Color.White};
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: calc(50% - 10px);
    transform: translateX(-50%) translateY(calc(-50% + 20px));
    z-index: 0;
    box-shadow: ${shadows.tabbar};
    transition: all 0.2s ease;
  }

  &:after {
    content: '';
    display: block;
    width: 110%;
    height: 90px;
    background: ${Color.White};
    opacity: 0;
    position: absolute;
    left: 50%;
    top: calc(50% + 5px);
    transform: translateX(-50%) translateY(-50% - 10px);
    z-index: 1;
    transition: all 0.2s ease;
  }
  ${({ $active }) => $active && IconWrapperActive}
`;
