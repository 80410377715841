import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import styled, { css } from 'styled-components';

type AccordionChevronProps = {
  orientation?: 'up' | 'down';
  size?: 'small' | 'large';
  className?: string;
};

const Wrapper = styled.div<AccordionChevronProps>`
  transition: transform 0.3s ease;

  ${({ orientation }) =>
    orientation === 'up' &&
    css`
      transform: rotate(180deg);
    `};
`;

const AccordionChevron = (props: AccordionChevronProps) => {
  const { orientation = 'down', size = 'small', className } = props;
  return (
    <Wrapper orientation={orientation} className={className}>
      <SvgIcon icon="chevron-down" size={size} />
    </Wrapper>
  );
};

export default AccordionChevron;
