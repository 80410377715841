'use client';

import NoticeField from '@/newComponents/NoticeField/NoticeField';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import { NotificationBlockList, NotificationType } from '@/types';
import { usePathname } from 'next/navigation';
import React from 'react';

import { Color, spacing } from '@/global/style/variables';
import NotificationHelper from '@/global/utils/NotificationHelper';

import { Container, ContentBody } from '@/components/CustomerNotification/CustomerNotification.styled';

type CustomerNotificationProps = {
  notificationBlockList?: NotificationBlockList | null;
};

const mapNotificationType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.IssueCritical:
      return NoticeFieldPreset.Error;
    case NotificationType.IssueMinor:
      return NoticeFieldPreset.Warning;
    case NotificationType.Ok:
      return NoticeFieldPreset.Success;
    case NotificationType.Information:
    default:
      return NoticeFieldPreset.Notice;
  }
};

const getBackgroundColor = (preset: NoticeFieldPreset) => {
  switch (preset) {
    case NoticeFieldPreset.Success:
      return Color.Nyanza;
    case NoticeFieldPreset.Warning:
      return Color.Sand;
    case NoticeFieldPreset.Error:
      return Color.Rose;
    case NoticeFieldPreset.Notice:
    default:
      return Color.Sky;
  }
};

const CustomerNotification = (props: CustomerNotificationProps) => {
  const pathname = usePathname() ?? '/';
  const { notificationBlockList } = props;

  if (!notificationBlockList) return null;

  return notificationBlockList.map((notification, index) => {
    if (notification.content) {
      const { notificationType, notificationHeadline, notificationBody, notificationShowOnPages } =
        notification.content;
      if (!NotificationHelper.shouldShowNotification(notificationShowOnPages?.$values, pathname)) {
        return null;
      }
      const preset = mapNotificationType(notificationType);
      return (
        <Container key={`notice-field-basic-${index.toString()}`} $backgroundColor={getBackgroundColor(preset)}>
          <ContentBody>
            <NoticeField
              header={notificationHeadline}
              text={notificationBody}
              data-testid="customer-notification"
              preset={preset}
              showBorder={false}
              customPadding={`${spacing.x2} 0`}
            />
          </ContentBody>
        </Container>
      );
    }
    return null;
  });
};

export default CustomerNotification;
