'use client';

import { PageKey } from '@/constants';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { CmsSettings } from '@/types';
import { useParams, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import useScrollLockBody from '@/global/hooks/useScrollLockBody';
import { ScrollPosition, ScrollState, useAppScrollPosition } from '@/global/hooks/useScrollPosition';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { Color } from '@/global/style/variables';

import AppDropDownMenu from '@/components/AppHeader/AppDropDownMenu/AppDropDownMenu';
import {
  DropDownWrapper,
  Header,
  PositionWrapper,
  ScrollWrapper,
  TitleIconClickableWrapper,
} from '@/components/AppHeader/AppHeader.styled';
import CustomerNotification from '@/components/CustomerNotification/CustomerNotification';

const basePageKeys = [PageKey.Abonnemang, PageKey.Betalnigar, PageKey.Kontoinstallningar];

function getBackRoute(pageKey?: PageKey, subscriptionId?: string | null): { route: string; label: string } | null {
  switch (pageKey) {
    case PageKey.Addsurf:
    case PageKey.Aktiveraesim:
    case PageKey.Avsluta:
    case PageKey.Bestallsim:
    case PageKey.Bytabonnemang:
    case PageKey.Cancelled:
    case PageKey.Extrausers:
      return { route: `/mina-sidor/${subscriptionId}`, label: 'Tillbaka till abonnemanget' };
    case PageKey.Kvitto:
      return { route: '/mina-sidor/betalningar', label: 'Tillbaka till betalningar' };
    case PageKey.Abonnemangsdetalj:
    case PageKey.ExtrauserSales:
      return { route: '/mina-sidor', label: 'Tillbaka till översikt' };
    default:
      return null;
  }
}

const MenuIcon = (dropDownVisible: boolean, isBasePage?: boolean) => {
  if (isBasePage) {
    if (dropDownVisible) {
      return <SvgIcon icon="cross" />;
    }
    return <SvgIcon icon="logout" />;
  }
  return <SvgIcon icon="chevron-left" />;
};

type AppHeaderProps = {
  pageKey?: PageKey;
};

const AppHeader = (props: AppHeaderProps) => {
  const { pageKey } = props;

  const { account, isApp } = useAppContext();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const notificationBlockList = settings.notificationBlockList ?? null;

  const [lockBody, setLockBody] = useState(false);
  const [, scrollPosition, scrollState] = useAppScrollPosition();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [blockScroll, allowScroll] = useScrollLockBody();

  const isBasePage = pageKey && basePageKeys.includes(pageKey);
  const router = useRouter();
  const params = useParams();

  const backRoute = getBackRoute(pageKey, params?.subscriptionid?.toString());

  useEffect(() => {
    if (lockBody) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [lockBody, allowScroll, blockScroll]);

  useEffect(() => {
    if (lockBody) {
      setLockBody(!!dropDownVisible);
    }
  }, [dropDownVisible, lockBody]);

  if (!account || !isApp) return null;

  return (
    <>
      <PositionWrapper>
        <ScrollWrapper
          data-scroll-state={dropDownVisible ? ScrollState.UP : scrollState}
          data-scroll-position={dropDownVisible ? ScrollPosition.TOP : scrollPosition}
          data-dropdown-visible={dropDownVisible}
        >
          <TitleIconClickableWrapper
            className="logout-button"
            data-testid="app-back-button"
            onClick={() => {
              if (isBasePage) {
                setDropDownVisible(!dropDownVisible);
              } else if (backRoute) {
                router.push(backRoute.route);
              }
            }}
          >
            {MenuIcon(dropDownVisible, isBasePage)}
            <Header type={TypographyType.ExpressiveL} color={Color.White} text={backRoute?.label} />
          </TitleIconClickableWrapper>
          <DropDownWrapper visible={dropDownVisible} setVisible={setDropDownVisible}>
            <AppDropDownMenu visible={dropDownVisible} setDropDownVisible={setDropDownVisible} />
          </DropDownWrapper>
        </ScrollWrapper>
      </PositionWrapper>
      <CustomerNotification notificationBlockList={notificationBlockList} />
    </>
  );
};

export default AppHeader;
