'use client';

import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { HallonColor } from '@/global/utils/styleTypes';

import {
  AccordionBody,
  AccordionBodyInner,
  AccordionListItem,
  AccordionTitleContainer,
  StyledSvgIcon,
  StyledTypography,
  TitleWrapper,
} from '@/components/Accordion/Accordion.styled';
import AccordionChevron from '@/components/Accordion/AccordionChevron';

export type AccordionProps = {
  ['data-testid']: string;
  title?: string;
  titleIcon?: IconKey;
  titleElementAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  subTitleContent?: React.ReactNode;
  children: React.ReactNode;
  openStateIndicator?: 'icon' | 'text';
  isOpen?: boolean;
  onOpen?: (open: boolean) => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  backgroundColor?: HallonColor;
  color?: HallonColor;
  borderRadius?: boolean;
  seoFriendly?: boolean;
};

const Accordion = (props: AccordionProps) => {
  const {
    'data-testid': dataTestId,
    title,
    titleIcon,
    titleElementAs = 'span',
    subTitleContent,
    children,
    openStateIndicator = 'icon',
    isOpen: externalOpen,
    onOpen,
    disabled,
    size = 'medium',
    className,
    backgroundColor,
    color = Color.MineShaft,
    borderRadius = true,
    seoFriendly = false,
  } = props;

  const [open, setOpen] = useState(externalOpen ?? false);

  useEffect(() => {
    setOpen(Boolean(externalOpen));
  }, [externalOpen]);

  useEffect(() => {
    if (disabled) setOpen(false);
  }, [disabled]);

  const getTitleTypographyProps = (
    accordionSize: AccordionProps['size']
  ): { type: TypographyType.Detail | TypographyType.Body; fontWeight: FontWeight.Medium | FontWeight.SemiBold } => {
    switch (accordionSize) {
      case 'small':
        return {
          type: TypographyType.Detail,
          fontWeight: FontWeight.Medium,
        };
      case 'large':
      case 'medium':
      default:
        return {
          type: TypographyType.Body,
          fontWeight: FontWeight.SemiBold,
        };
    }
  };

  const renderAccordionContent = () => {
    // Seo friendly means we always render the contents, just animate the height of the container
    // to show not show the content.
    if (seoFriendly) {
      return (
        <motion.div
          style={{ overflow: 'hidden', backgroundColor }}
          initial={{ height: 0 }}
          animate={{ height: open ? 'auto' : 0 }}
          transition={{ duration: 0.3 }}
          exit={{ height: 0 }}
          key="container"
        >
          <AccordionBodyInner>{children}</AccordionBodyInner>
        </motion.div>
      );
    }

    // If default behaviour, content is only rendered if the accordion is open
    if (!open) return null;
    return (
      <AnimatePresence mode="wait">
        <AccordionBody
          $backgroundColor={backgroundColor}
          initial={{ height: 0 }}
          animate={{
            height: 'auto',
            transition: {
              height: {
                duration: 0.4,
              },
            },
          }}
          transition={{ duration: 0.5 }}
          exit={{ height: 0 }}
          key="accordion-children"
          className="accordion-body"
        >
          <AccordionBodyInner>{children}</AccordionBodyInner>
        </AccordionBody>
      </AnimatePresence>
    );
  };

  const typographyProps = getTitleTypographyProps(size);

  return (
    <AccordionListItem
      $borderRadius={borderRadius}
      $size={size}
      $disabled={Boolean(disabled)}
      $open={open}
      className={className}
    >
      <AccordionTitleContainer
        data-testid={dataTestId}
        className="accordion-button"
        disabled={disabled}
        $size={size}
        aria-expanded={open}
        aria-controls={title}
        $backgroundColor={backgroundColor}
        onClick={() => {
          if (onOpen) onOpen(!open);
          setOpen(!open);
        }}
      >
        <TitleWrapper $hasIcon={Boolean(titleIcon)}>
          {titleIcon && <StyledSvgIcon size={size === 'medium' ? 'large' : size} icon={titleIcon} color={color} />}
          <StyledTypography
            text={title}
            color={color}
            type={typographyProps.type}
            fontWeight={typographyProps.fontWeight}
            elementAs={titleElementAs}
          />

          <span>
            {openStateIndicator === 'icon' ? (
              <AccordionChevron orientation={open ? 'up' : 'down'} />
            ) : (
              <span>{open ? 'Avbryt' : 'Ändra'}</span>
            )}
          </span>
        </TitleWrapper>

        {subTitleContent && <div className="subTitleContent">{subTitleContent}</div>}
      </AccordionTitleContainer>

      {renderAccordionContent()}
    </AccordionListItem>
  );
};

export default Accordion;
