import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Brand, Link } from '@/types';

import { HeaderContainer } from '@/global/layouts/parts/Header/Header.styled';
import { Color } from '@/global/style/variables';

import { BrandItem, HeaderBrandContainer } from './HeaderBrand.styled';

type HeaderBrandProps = {
  brand: Brand;
  b2cText: string;
  b2cLink: Link | null;
  b2bText: string;
  b2bLink: Link | null;
};

const HeaderBrand = (props: HeaderBrandProps) => {
  const { brand, b2cText, b2bText, b2cLink, b2bLink } = props;

  return (
    <HeaderContainer $backgroundColor={Color.Hallon6}>
      <HeaderBrandContainer $backgroundColor={Color.Hallon6}>
        <BrandItem
          $selected={brand === Brand.B2C}
          data-testid="header-brand-b2c"
          id={Brand.B2C}
          href={b2cLink?.url ?? '/'}
        >
          <Typography
            text={b2cText}
            color={brand === Brand.B2C ? Color.Hallon1 : Color.MineShaft}
            type={TypographyType.Detail}
          />
        </BrandItem>

        <BrandItem
          $selected={brand === Brand.B2B}
          data-testid="header-brand-b2b"
          id={Brand.B2B}
          href={b2bLink?.url ?? '/foretag'}
        >
          <Typography
            text={b2bText}
            color={brand === Brand.B2B ? Color.Hallon1 : Color.MineShaft}
            type={TypographyType.Detail}
          />
        </BrandItem>
      </HeaderBrandContainer>
    </HeaderContainer>
  );
};

export default HeaderBrand;
