import { gtmLogout } from '@/global/gtm';

export const logoutRedirect = (userInitiated = false, backToMyPages = false) => {
  if (typeof window === 'undefined') return undefined;

  if (userInitiated) {
    gtmLogout();
  }

  const redirectUrl = new URL(`${process.env.BASE_URL}/api/auth/logout`);
  if (backToMyPages) {
    redirectUrl.searchParams.set('backToMyPages', 'true');
  }

  window.location.href = redirectUrl.href;
};
