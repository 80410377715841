import Typography from '@/newComponents/Typography/Typography';

import { Link as LinkType } from '@/types';

import { FontWeight } from '@/constants';
import { ActiveIndicator, MainLinkContainer, StyledLink } from './MainLink.styled';

type MainLinkProps = {
  text: string;
  link: LinkType;
  active: boolean;
};

const MainLink = (props: MainLinkProps) => {
  const { text, link, active } = props;

  return (
    <MainLinkContainer $active={active} data-testid={link.name}>
      <StyledLink href={link.url}>
        <Typography text={text} fontWeight={FontWeight.SemiBold} />
      </StyledLink>

      <ActiveIndicator $active={active} />
    </MainLinkContainer>
  );
};

export default MainLink;
