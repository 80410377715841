'use client';

import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Brand, FooterMinimal } from '@/types';
import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import classNames from 'classnames';
import Link from 'next/link';
import linkVariants from '@/components/LinkLookingButton/LinkLookingButton.module.css';
import styles from './FooterBottom.module.css';

type FooterBottomProps = {
  minimalFooterData: FooterMinimal;
  brandProfile: Brand;
  hasBottomOverlay?: boolean;
};

const FooterBottom = (props: FooterBottomProps) => {
  const { minimalFooterData, brandProfile, hasBottomOverlay } = props;
  if (!minimalFooterData) return null;

  const { copyrightText, privacyPolicyLink, termsAndConditionsLink, socialMediaHeader, socialMediaLinks } =
    minimalFooterData;

  return (
    <div className={styles.footerBottomContainer}>
      <div
        className={classNames(styles.footerBottomContainerInner, {
          [styles.extraMarginBottom]: hasBottomOverlay,
        })}
      >
        <div className={styles.logoContainer}>
          {brandProfile === Brand.B2C ? (
            <Link className={styles.companyLink} data-testid="footer_navigation_logo_home_b2c" href="/">
              <SvgIcon
                icon="hallonLogo"
                size="auto"
                color={Color.White}
                title="Hallons logotyp, tillbaka till startsidan"
              />
            </Link>
          ) : (
            <Link className={styles.companyLink} data-testid="footer_navigation_logo_home_b2b" href="/foretag">
              <SvgIcon
                icon="hallonLogoCompany"
                size="auto"
                color={Color.White}
                title="Hallons logotyp, tillbaka till startsidan för företag"
              />
            </Link>
          )}
          <Divider />

          <div className={styles.termsAndLinksContainer}>
            <Typography
              text={getCmsTextWithValues(copyrightText, {
                year: new Date().getFullYear().toString(),
              })}
              type={TypographyType.Detail}
              color={Color.White}
              align="center"
            />

            <div className={styles.linksContainer}>
              {privacyPolicyLink?.name && (
                <>
                  <a
                    className={classNames(styles.link, linkVariants.variantWhite)}
                    href={privacyPolicyLink.url}
                    data-testid="footer-integrity-policy-link"
                  >
                    {privacyPolicyLink.name}
                  </a>

                  <Typography text="|" color={Color.White} type={TypographyType.Detail} />
                </>
              )}
              <a
                href={termsAndConditionsLink.url}
                className={classNames(styles.link, linkVariants.variantWhite)}
                data-testid="footer-general-terms-link"
              >
                {termsAndConditionsLink.name}
              </a>
            </div>
          </div>
        </div>

        <div className={styles.socialMediaContainer}>
          <Typography text={socialMediaHeader} fontWeight={FontWeight.SemiBold} color={Color.White} align="center" />
          <Divider />
          <div className={styles.socialMediaIconContainer}>
            {socialMediaLinks.map((socialmediaLink) => (
              <a
                key={socialmediaLink.icon}
                href={socialmediaLink.link.url}
                target="_blank"
                rel="noreferrer"
                data-testid={`footer-socialmedia-${socialmediaLink.link.name}`}
                aria-label={`Hallon på ${socialmediaLink.link.name}`}
                className={classNames(styles.link, linkVariants.variantWhite)}
              >
                <SvgIcon
                  color={Color.White}
                  icon={socialmediaLink.icon}
                  title={`Hallon på ${socialmediaLink.link.name}`}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
