import debounce from 'lodash.debounce';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const useWindowWidth = (debounceDelay = 250): [number, Dispatch<SetStateAction<number>>] => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleWindowResizeDebounce = debounce(() => setWindowWidth(window.innerWidth), debounceDelay);
    window.addEventListener('resize', handleWindowResizeDebounce);
    return () => {
      window.removeEventListener('resize', handleWindowResizeDebounce);
    };
  }, [debounceDelay]);

  return [windowWidth, setWindowWidth];
};

export default useWindowWidth;
