import { ApplicationInsights, Snippet } from '@microsoft/applicationinsights-web';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { type ReactNode, useEffect, useMemo } from 'react';
import Cookies from 'universal-cookie';

const BANNERFLOW_RESIZE_OBSERVER_ERROR_MESSAGE =
  'ErrorEvent: ResizeObserver loop completed with undelivered notifications.';

const ignoredErrorMessages = [BANNERFLOW_RESIZE_OBSERVER_ERROR_MESSAGE];

export const setupAppInsights = (pathname: string) => {
  const config: Snippet['config'] = {
    connectionString: process.env.APPLICATION_INSIGHT_CONNECTION_STRING,
    enableAutoRouteTracking: true,
  };

  // TODO: When boost.ai has whitelisted the traceparent header, remove this if statement.
  if (pathname === '/identify/login') {
    config.correlationHeaderExcludedDomains = ['*'];
  }

  const appInsights = new ApplicationInsights({ config });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope) => {
    if (ignoredErrorMessages.includes(envelope.data?.message)) {
      return false;
    }

    /* eslint-disable no-param-reassign */
    if (envelope && envelope.tags) {
      envelope.tags['ai.cloud.role'] = 'Web.Client';
      envelope.tags['ai.cloud.roleName'] = 'Web.Client';
      envelope.tags['ai.cloud.roleInstance'] = 'Web.Client';
    }

    // Adding custom properties from the window object
    if (typeof window !== 'undefined') {
      envelope.data = envelope.data || {};
      envelope.data.isApp = window.isApp || false;
      envelope.data.rnHallonAppCode = window.rnHallonAppCode || '';
      envelope.data.appPlatform = window.appPlatform || 'web';
    }
    /* eslint-enable no-param-reassign */
  });

  appInsights.trackPageView();
  window.appInsights = appInsights;
};

type AppWrapperProps = {
  children: ReactNode;
  isApp: boolean;
};

const AppWrapper = ({ children, isApp }: AppWrapperProps) => {
  const cookies = useMemo(() => new Cookies(), []);
  const router = useRouter();

  useEffect(() => {
    if (process.env.APPLICATION_INSIGHT_CONNECTION_STRING) {
      const { pathname } = router;

      // Setup Application Insights
      setupAppInsights(pathname);
    }
  }, [router]);

  useEffect(() => {
    if (!isApp && window && window.isApp === 'true') {
      cookies.set('isApp', true, { domain: '.hallon.se', path: '/' });
    }
  }, [isApp, cookies]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0" />
      </Head>
      {children}
    </>
  );
};

export default AppWrapper;
