import { Account, Brand } from '@/types';
import { usePathname } from 'next/navigation';

export const usePageBrand = (account?: Account) => {
  const pathname = usePathname();
  const pathnameIncludesB2b = pathname?.includes('foretag');
  const pathnameIncludesMinaSidor = pathname?.includes('mina-sidor');
  let pageBrand = pathnameIncludesB2b ? Brand.B2B : Brand.B2C;

  if (pathnameIncludesMinaSidor) {
    pageBrand = account?.type || Brand.B2C;
  }

  return pageBrand;
};
