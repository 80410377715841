'use client';

import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { Brand, HeaderComplementaryNavigation, HeaderIconLink, HeaderLink } from '@/types';
import { signIn, useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

import { HeaderContainer } from '@/global/layouts/parts/Header/Header.styled';
import { isHeaderItemActive } from '@/global/layouts/parts/Header/Header.utils';
import HamburgerMenu from '@/global/layouts/parts/Header/HeaderMain/HamburgerMenu/HamburgerMenu';
import MainIconLink from '@/global/layouts/parts/Header/HeaderMain/MainIconLink/MainIconLink';
import MainLink from '@/global/layouts/parts/Header/HeaderMain/MainLink/MainLink';
import { Color } from '@/global/style/variables';
import { isAuthenticated } from '@/global/utils/Authenticated';

import {
  ActiveCartIndicator,
  HeaderMainContainer,
  HeaderMainContent,
  HeaderMainPlaceholder,
  LogoContainer,
  MainIconLinksContainer,
  MainIconLinksNav,
  MainLinksContainer,
  MainLinksNav,
  SvgContainer,
} from '@/global/layouts/parts/Header/HeaderMain/HeaderMain.styled';
import { useHeaderRef } from '@/global/context/HeaderRefContext';
import MyPagesMenu from './MyPagesMenu/MyPagesMenu';

type HeaderMainProps = {
  mainLinks: HeaderLink[];
  mainIconLinks: HeaderComplementaryNavigation[];
  loginIconLink: HeaderIconLink | null;
  myPagesIconLink: HeaderIconLink | null;
  brand: Brand;
  sticky: boolean;
  isMinimal?: boolean;
  hasActiveCart: boolean;
  loggedIn: boolean | null;
};

const oneHourInMs = 60 * 60 * 1000;

const HeaderMain = (props: HeaderMainProps) => {
  const {
    mainLinks,
    mainIconLinks,
    loginIconLink,
    myPagesIconLink,
    brand = Brand.B2B,
    sticky,
    isMinimal,
    hasActiveCart,
    loggedIn,
  } = props;
  const pathname = usePathname() ?? '/';

  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);
  const session = useSession();
  const isLoggedIn = loggedIn !== null ? loggedIn : isAuthenticated(session, oneHourInMs);
  const { headerRef } = useHeaderRef();

  const renderMenuIcons = () => {
    return mainIconLinks.map((iconLink) => {
      const menuIconActive = isHeaderItemActive(pathname, iconLink.link.url);
      const isCartIcon = iconLink.type === 'cart-b2c' || iconLink.type === 'cart-b2b';
      const showIndicator = hasActiveCart && isCartIcon;
      const ariaLabel = showIndicator ? 'Varukorg med innehåll' : iconLink.text;

      return (
        <MainIconLink
          data-testid={iconLink.link.url}
          key={iconLink.link.url}
          iconLink={iconLink}
          active={menuIconActive}
          isMinimal={isMinimal}
          ariaLabel={ariaLabel}
        >
          {showIndicator && <ActiveCartIndicator />}
        </MainIconLink>
      );
    });
  };

  return (
    <HeaderContainer $backgroundColor={Color.White}>
      <HeaderMainContainer ref={headerRef} $sticky={sticky}>
        <HeaderMainContent $isMinimal={isMinimal}>
          {brand === Brand.B2C ? (
            <LogoContainer href="/" aria-label="Till Hallons startsida" data-testid="navigation_logo_home_b2c" $isB2C>
              <SvgContainer>
                <SvgIcon title="Till Hallons startsida" icon="hallonLogo" size="auto" />
              </SvgContainer>
            </LogoContainer>
          ) : (
            <LogoContainer
              href="/foretag"
              aria-label="Till Hallons startsida för företag"
              data-testid="navigation_logo_home_b2b"
            >
              <SvgContainer>
                <SvgIcon title="Till Hallons startsida för företag" icon="hallonLogoCompany" size="auto" />
              </SvgContainer>
            </LogoContainer>
          )}
          {!isMinimal && (
            <MainLinksNav>
              <MainLinksContainer $numberOfLinks={mainLinks.length}>
                {mainLinks.map((mainLink) => (
                  <MainLink
                    key={mainLink.link.url}
                    text={mainLink.text}
                    link={mainLink.link}
                    active={isHeaderItemActive(pathname, mainLink.link.url)}
                  />
                ))}
              </MainLinksContainer>
            </MainLinksNav>
          )}
          <MainIconLinksNav>
            <MainIconLinksContainer>
              {renderMenuIcons()}

              {!isMinimal && (
                <>
                  {isLoggedIn && myPagesIconLink ? (
                    <MyPagesMenu myPagesIconLink={myPagesIconLink} shouldDisableLinkActiveState={hamburgerMenuIsOpen} />
                  ) : (
                    loginIconLink && (
                      <MainIconLink
                        data-testid={loginIconLink.link.url}
                        iconLink={loginIconLink}
                        active={pathname.includes('identify/login') && !hamburgerMenuIsOpen}
                        onClick={() => signIn('hallon', { callbackUrl: '/mina-sidor' })}
                      />
                    )
                  )}

                  <HamburgerMenu
                    mainLinks={mainLinks}
                    mainIcons={mainIconLinks}
                    setHamburgerMenuIsOpen={setHamburgerMenuIsOpen}
                  />
                </>
              )}
            </MainIconLinksContainer>
          </MainIconLinksNav>
        </HeaderMainContent>
      </HeaderMainContainer>
      <HeaderMainPlaceholder $show={sticky} />
    </HeaderContainer>
  );
};

export default HeaderMain;
