import { useEffect, useRef } from 'react';

export function useClickOutside<T extends HTMLElement>(
  handler: () => void,
  ignoreOutsideClickClassName = 'ignore-outside-click'
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (event: any) => {
      const path = event.path || event.composedPath();
      let shouldIgnoreClick = false;
      let isInside = false;
      for (let i = 0, len = path.length; i < len; i += 1) {
        // Ignore clicks on elements with class ignore-outside-click (e.g. MyPagesMenu.tsx)
        if (path[i].classList?.contains(ignoreOutsideClickClassName)) {
          shouldIgnoreClick = true;
          break;
        }
        // Check if click is inside of the ref element
        if (path[i] === ref?.current) {
          isInside = true;
          break;
        }
      }

      // Call handler function
      if (ref?.current && !shouldIgnoreClick && !isInside) {
        handler();
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, ignoreOutsideClickClassName]);

  return ref;
}
