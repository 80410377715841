import { ButtonText, IconWrapper, StyledButton } from './AppTabBarButton.styled';

type AppTabBarButtonProps = {
  onClick: () => void;
  IconSvg: () => JSX.Element;
  dataTestId?: string;
  label: string;
  active?: boolean;
};

const AppTabBarButton = (props: AppTabBarButtonProps) => {
  const { dataTestId, onClick, IconSvg, label, active } = props;
  return (
    <StyledButton data-testid={dataTestId} onClick={onClick}>
      <IconWrapper $active={active}>
        <IconSvg />
      </IconWrapper>
      <ButtonText $active={active}>{label}</ButtonText>
    </StyledButton>
  );
};

export default AppTabBarButton;
