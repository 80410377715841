import { SelectedAccountIdCookie } from '@/constants';
import Button from '@/newComponents/Button/Button';
import { ButtonSize } from '@/newComponents/Button/Button.utils';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Account, RatorAccount } from '@/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import { logoutRedirect } from '@/global/utils/logout';

import BaseJson from '@/pages/api/cms/base.json';

import {
  AccountButtonWrapper,
  AccountSlider,
  AccountSliderItem,
  BackgroundWrapper,
  BottomButtonWrapper,
  ChangeAccountButton,
  Container,
  Inner,
} from '@/components/AppHeader/AppDropDownMenu/AppDropDownMenu.styled';
import AppDropDownMenuAccountButton from '@/components/AppHeader/AppDropDownMenuAccountButton/AppDropDownMenuAccountButton';

const BREAK_AT_NUMBER_OF_ACCOUNTS = 3;
const ANIMATION_DELAY = 300;

const renderChangeAccountsButtons = (
  account: Account,
  accounts: Account[],
  setDropDownVisible: (dropDownVisible: boolean) => void,
  isLoadingFullAccountInfo: boolean,
  setIsLoadingFullAccountInfo: Dispatch<SetStateAction<boolean>>,
  markedId: number | null,
  setMarkedId: Dispatch<SetStateAction<null | number>>
) => {
  const handleSelectAccount = async (index: number, basicAccount: RatorAccount) => {
    // bug out early if the user just clicked an account and we're fetching it
    if (isLoadingFullAccountInfo) return;

    setIsLoadingFullAccountInfo(true);
    const RUN_AFTER_ANIMATION_DELAY = 600;
    try {
      setMarkedId(index);

      document.cookie = `${SelectedAccountIdCookie}=${basicAccount.userId}; path=/`;

      setTimeout(() => {
        setDropDownVisible(false);
        setMarkedId(null);
        setIsLoadingFullAccountInfo(false);
        window.location.href = '/mina-sidor/abonnemang';
      }, RUN_AFTER_ANIMATION_DELAY);
    } catch (error) {
      setMarkedId(null);
      setIsLoadingFullAccountInfo(false);
      logoutRedirect();
    }
  };

  return accounts?.map((basicAccount: RatorAccount, index: number) => {
    return (
      <AccountButtonWrapper key={basicAccount.userId}>
        <AppDropDownMenuAccountButton
          marked={basicAccount.userId === account.userId}
          email={basicAccount.email}
          isLoading={isLoadingFullAccountInfo}
          isActive={index === markedId}
          onClick={() => handleSelectAccount(index, basicAccount)}
        />
      </AccountButtonWrapper>
    );
  });
};

const renderActiveAccount = (
  account: Account,
  accounts: Account[],
  hasMultipleAccounts: boolean,
  setDropDownVisible: (dropDownVisible: boolean) => void,
  isLoadingFullAccountInfo: boolean,
  setIsLoadingFullAccountInfo: React.Dispatch<React.SetStateAction<boolean>>,
  markedId: number | null,
  setMarkedId: React.Dispatch<React.SetStateAction<number | null>>
) => {
  if (hasMultipleAccounts) {
    if (accounts.length > BREAK_AT_NUMBER_OF_ACCOUNTS && account) {
      return (
        <AccountButtonWrapper>
          <span>{account?.email || BaseJson.base.appDropDownMenu_NoActiveAccount}</span>
        </AccountButtonWrapper>
      );
    }
    return renderChangeAccountsButtons(
      account,
      accounts,
      setDropDownVisible,
      isLoadingFullAccountInfo,
      setIsLoadingFullAccountInfo,
      markedId,
      setMarkedId
    );
  }

  return (
    <AccountButtonWrapper>
      <span>{accounts[0].email}</span>
    </AccountButtonWrapper>
  );
};

type AppDropDownMenuProps = {
  visible?: boolean;
  setDropDownVisible: (dropDownVisible: boolean) => void;
};

type SliderItemRefObject = {
  clientHeight: string;
};

const AppDropDownMenu = (props: AppDropDownMenuProps) => {
  const { visible = false, setDropDownVisible } = props;
  const { account, accounts } = useAppContext() as { account: Account; accounts: Account[] };

  const hasMultipleAccounts = accounts && accounts.length > 1;
  const hasMoreThanXAccounts = accounts && accounts.length > BREAK_AT_NUMBER_OF_ACCOUNTS;
  const [isAccountSwitchActive, setIsAcctountSwitchActive] = useState(false);
  const [willAccountSwitch, setWillAccountSwitch] = useState(false);
  const [showAllSlides, setShowAllSlides] = useState(false);
  const sliderItemFirst = useRef<SliderItemRefObject>(null);
  const sliderItemLast = useRef<SliderItemRefObject>(null);
  const [sliderHeight, setSliderHeight] = useState<string>();
  const [isLoadingFullAccountInfo, setIsLoadingFullAccountInfo] = useState(false);
  const [markedId, setMarkedId] = useState<number | null>(null);
  const { base } = BaseJson;

  const setAccountsHeader = () => {
    if (hasMultipleAccounts) {
      return willAccountSwitch ? base.appDropDownMenuHeader_MultiAccount : base.appDropDownMenuHeader_ActiveAccount;
    }
    return base.appDropDownMenuHeader_SingleAccount;
  };

  useEffect(() => {
    setShowAllSlides(true);

    const showAllSlidesTimeout = setTimeout(() => {
      setIsAcctountSwitchActive(willAccountSwitch);
      setShowAllSlides(false);
    }, ANIMATION_DELAY);

    return () => {
      clearTimeout(showAllSlidesTimeout);
    };
  }, [willAccountSwitch]);

  useEffect(() => {
    if (showAllSlides) {
      const height = willAccountSwitch ? sliderItemLast?.current?.clientHeight : sliderItemFirst?.current?.clientHeight;
      setSliderHeight(height);
    }
  }, [showAllSlides, willAccountSwitch]);

  useEffect(() => {
    let accountSwitchTimeout: ReturnType<typeof setTimeout>;
    if (!visible) {
      accountSwitchTimeout = setTimeout(() => {
        setIsAcctountSwitchActive(false);
        setWillAccountSwitch(false);
      }, ANIMATION_DELAY);
    }
    return () => {
      clearTimeout(accountSwitchTimeout);
    };
  }, [visible]);

  const handleLogoutClick = () => {
    if (willAccountSwitch) {
      setWillAccountSwitch(!willAccountSwitch);
    } else {
      logoutRedirect(true, true);
    }
  };

  const memoedChangeAccountsButtons = useMemo(
    () =>
      renderChangeAccountsButtons(
        account,
        accounts,
        setDropDownVisible,
        isLoadingFullAccountInfo,
        setIsLoadingFullAccountInfo,
        markedId,
        setMarkedId
      ),
    [account, accounts, isLoadingFullAccountInfo, markedId, setDropDownVisible]
  );

  const memoedActiveAccount = useMemo(
    () =>
      renderActiveAccount(
        account,
        accounts,
        hasMultipleAccounts,
        setDropDownVisible,
        isLoadingFullAccountInfo,
        setIsLoadingFullAccountInfo,
        markedId,
        setMarkedId
      ),
    [account, accounts, hasMultipleAccounts, isLoadingFullAccountInfo, markedId, setDropDownVisible]
  );

  const logoutLabel = willAccountSwitch ? base.appDropDownMenuCTA_Back : base.appDropDownMenuCTA_Logout;

  return (
    <Container>
      <BackgroundWrapper>
        <Inner>
          <Typography type={TypographyType.ExpressiveL} text={setAccountsHeader()} aria-live="polite" />
          <div style={{ overflow: 'hidden' }}>
            <AccountSlider data-is-switch-active={willAccountSwitch} style={{ height: sliderHeight }}>
              <AccountSliderItem>
                {(!isAccountSwitchActive || showAllSlides) && account && memoedActiveAccount}
                {hasMoreThanXAccounts && (!isAccountSwitchActive || showAllSlides) && (
                  <ChangeAccountButton
                    onClick={() => {
                      setWillAccountSwitch(!isAccountSwitchActive);
                    }}
                  >
                    {base.appDropDownMenuCTA_SwitchAccount}
                    <SvgIcon icon="chevron-right" size="small" />
                  </ChangeAccountButton>
                )}
              </AccountSliderItem>
              <AccountSliderItem>
                {(isAccountSwitchActive || showAllSlides) && account && memoedChangeAccountsButtons}
              </AccountSliderItem>
            </AccountSlider>
          </div>
          <BottomButtonWrapper>
            <Button
              label={logoutLabel}
              data-testid="app-dropdown-logout-button"
              onClick={handleLogoutClick}
              size={ButtonSize.Fullwidth}
            />
          </BottomButtonWrapper>
        </Inner>
      </BackgroundWrapper>
    </Container>
  );
};

export default AppDropDownMenu;
