import { HamburgerIconContainer } from './HamburgerIcon.styled';

type HamburgerIconProps = {
  isOpen: boolean;
};

const HamburgerIcon = (props: HamburgerIconProps) => {
  const { isOpen } = props;

  return (
    <HamburgerIconContainer $isOpen={isOpen}>
      <span />
      <span />
      <span />
    </HamburgerIconContainer>
  );
};

export default HamburgerIcon;
