import styled from 'styled-components';

import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const MainLinkContainer = styled.li<{ $active: boolean }>`
  display: grid;
  list-style: none;
  cursor: pointer;
`;

export const StyledLink = styled.a`
  padding: ${spacing.x05} ${spacing.x1} 2px ${spacing.x1};
  border-radius: ${NewBorderRadius.XSmall};
  border: 2px solid ${Color.Transparent};
  outline: none;
  margin-bottom: ${spacing.x15};

  &:hover {
    background-color: ${Color.MineShaftHover};
  }

  &:active {
    background-color: ${Color.MineShaftActive};
  }

  &:focus-visible {
    border-color: ${Color.Ocean};
  }
`;

export const ActiveIndicator = styled.div<{ $active: boolean }>`
  width: calc(100% - ${spacing.x2});
  margin: 0 auto;
  border: 2px solid ${Color.Transparent};
  position: relative;
  top: 1px;

  ${({ $active }) => $active && `border-color: ${Color.Blue};`}
`;
