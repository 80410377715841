import { Brand, CmsSettings, HeaderLink } from '@/types';

export const getMainLinks = (brand: Brand, cmsSettings: CmsSettings | null) => {
  if (!cmsSettings) return [];
  if (brand === Brand.B2C) {
    return cmsSettings.headerB2CmainNavigation.map((block) => block.content);
  }
  return cmsSettings.headerB2BmainNavigation.map((block) => block.content);
};

export const getComplementaryIcons = (brand: Brand, cmsSettings: CmsSettings | null) => {
  if (!cmsSettings) return [];
  if (brand === Brand.B2C) {
    return cmsSettings.headerB2CcomplementaryNavigation.map((block) => block.content);
  }
  return cmsSettings.headerB2BcomplementaryNavigation.map((block) => block.content);
};

export const getLoginIcon = (brand: Brand, cmsSettings: CmsSettings | null) => {
  if (!cmsSettings) return null;
  if (brand === Brand.B2C) {
    return cmsSettings.headerB2CloginIcon.map((block) => block.content)[0] ?? null;
  }
  return cmsSettings.headerB2BloginIcon.map((block) => block.content)[0] ?? null;
};

export const getMyPagesIcon = (brand: Brand, cmsSettings: CmsSettings | null) => {
  if (!cmsSettings) return null;
  if (brand === Brand.B2C) {
    return cmsSettings.headerB2CmyPagesIcon.map((block) => block.content)[0] ?? null;
  }
  return cmsSettings.headerB2BmyPagesIcon.map((block) => block.content)[0] ?? null;
};

export const getSubLinks = (mainLinks: HeaderLink[], asPath: string, cmsSettings: CmsSettings | null) => {
  if (!cmsSettings) return null;

  if (asPath.includes('mina-sidor')) {
    return cmsSettings.headerMyPagesNavigation.map((block) => block.content);
  }

  const activeMainLink = mainLinks.find((mainLink) => {
    const asPathWithoutSlash = asPath.replaceAll('/', '');
    const linkUrlWithoutSlash = mainLink.link.url.replaceAll('/', '');
    return asPathWithoutSlash.startsWith(linkUrlWithoutSlash);
  });

  if (activeMainLink?.sublinks) {
    const activeMainSubLinks = activeMainLink.sublinks.map((sublink) => sublink.content);
    return activeMainSubLinks;
  }
  return [];
};

export const isHardwareProductRoute = (url: string) => {
  return /^\/mobiler\/[a-z0-9]+-[-+a-z0-9?%=&/]*$/.test(url.toLowerCase());
};

export const isHeaderItemActive = (asPath: string, linkUrl: string) => {
  const baseAsPath = asPath.replace('/foretag', '').split('/')[1];
  const cleanedLinkUrl = linkUrl.replace('/foretag', '').replaceAll('/', '');
  return baseAsPath === cleanedLinkUrl;
};
