'use client';

import React, { useMemo } from 'react';
import Header from '@/global/layouts/parts/Header/Header';
import AppHeader from '@/components/AppHeader/AppHeader';
import { PageKey } from '@/constants';
import { usePathname } from 'next/navigation';

const convertPathnameToPageKey = (pathname: string | null): PageKey => {
  if (!pathname) return PageKey.Abonnemang;
  const pageKey = pathname.split('/').pop();

  const pageKeyIsNumber = !Number.isNaN(Number(pageKey));
  if (pageKeyIsNumber) return PageKey.Abonnemangsdetalj;

  return pageKey as PageKey;
};

type AppRouterHeaderProps = {
  loggedIn: boolean;
  isApp: boolean;
};

const AppRouterHeader = (props: AppRouterHeaderProps) => {
  const { loggedIn, isApp } = props;
  const pathname = usePathname();

  const headerSettings = useMemo(() => {
    const hideBrandSectionPaths = ['/offert', '/foretag/offert', '/identify/'];
    const mnimalHeaderPaths = ['/offert', '/foretag/offert', '/identify/'];

    const showBrandSection = !hideBrandSectionPaths.some((path) => pathname?.includes(path));
    const isMinimal = mnimalHeaderPaths.some((path) => pathname?.includes(path));

    return { showBrandSection, isMinimal };
  }, [pathname]);

  if (isApp) {
    return <AppHeader pageKey={convertPathnameToPageKey(pathname)} />;
  }

  return (
    <Header
      loggedIn={loggedIn}
      showBrandSection={headerSettings.showBrandSection}
      isMinimal={headerSettings.isMinimal}
    />
  );
};

export default AppRouterHeader;
