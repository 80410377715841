import { SelectedAccountIdCookie, FontWeight } from '@/constants';
import Button from '@/newComponents/Button/Button';
import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { type RatorAccount, type CmsSettings, type HeaderIconLink, AccountUserRole } from '@/types';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import { useClickOutside } from '@/global/hooks/useClickOutside';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import useScrollLockBody from '@/global/hooks/useScrollLockBody';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import MainIconLink from '@/global/layouts/parts/Header/HeaderMain/MainIconLink/MainIconLink';
import { breakpoints } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';

import { getCmsTextWithValues } from '@/global/utils/Cms';
import { logoutRedirect } from '@/global/utils/logout';

import RadioLinkButton from '@/components/RadioLinkButton/RadioLinkButton';

import { ButtonColor } from '@/newComponents/Button/Button.utils';
import classNames from 'classnames';
import styles from './MyPagesMenu.module.css';

type MyPagesMenuProps = {
  myPagesIconLink: HeaderIconLink;
  shouldDisableLinkActiveState: boolean;
};

const MyPagesMenu = (props: MyPagesMenuProps) => {
  const { myPagesIconLink, shouldDisableLinkActiveState } = props;
  const { icon, text } = myPagesIconLink;

  const { account, accounts, roles } = useAppContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedRatorAccount, setSelectedRatorAccount] = useState<RatorAccount | null>(null);

  const { settings } = useCmsContext<{ settings: CmsSettings }>();

  const menuOverlayRef = useClickOutside<HTMLLIElement>(() => setIsOpen(false));
  const router = useRouter();
  const pathname = usePathname();
  const isMobile = useMatchMedia(`(max-width:${breakpoints.sm})`);
  const [blockScroll, allowScroll] = useScrollLockBody();

  useEffect(() => {
    if (!isMobile) return;
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [allowScroll, blockScroll, isOpen, isMobile]);

  const handleSelectAccount = (ratorAccount: RatorAccount) => {
    setSelectedRatorAccount(ratorAccount);
  };

  const handleSubmitAccount = () => {
    try {
      if (!selectedRatorAccount?.userId) throw Error('No account was selected');

      document.cookie = `${SelectedAccountIdCookie}=${selectedRatorAccount.userId}; path=/`;
      if (pathname === '/mina-sidor/abonnemang') {
        router.refresh();
      } else {
        window.location.href = '/mina-sidor/abonnemang';
      }

      setIsOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <MainIconLink
      data-testid="my-pages-menu"
      ref={menuOverlayRef}
      active={(pathname?.includes(myPagesIconLink.link.url) || isOpen) && !shouldDisableLinkActiveState}
      isOpen={isOpen}
      iconLink={{
        link: {
          name: '',
          url: '*',
          target: null,
        },
        text,
        icon,
      }}
      secondaryIcon="chevron-down"
      shouldRotateSecondaryIcon
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      ignoreOutsideClick
    >
      <div
        className={classNames(styles.menuOverlay, {
          [styles.menuOverlayOpen]: isOpen,
          [styles.menuOverlayDesktop]: !isMobile,
        })}
      >
        <div className={styles.menuOverlayInner}>
          {roles.includes(AccountUserRole.Guest) ? (
            <Typography text="Inloggad som gästanvändare" color={Color.DarkGray1} />
          ) : (
            <button
              className={styles.styledButton}
              data-testid="go-to-my-pages-button"
              type="button"
              onClick={() => {
                window.location.href = '/mina-sidor/abonnemang';
                setIsOpen(false);
              }}
            >
              <Typography text={settings.headerGoToMyPages} fontWeight={FontWeight.SemiBold} />
              <SvgIcon size="small" icon="chevron-right" />
            </button>
          )}
          <Divider marginY={spacing.x05} />
          {account?.email && (
            <Typography
              text={getCmsTextWithValues(settings.headerLoggedInAs, { email: account.email })}
              color={Color.DarkGray1}
            />
          )}
          <Divider />
          <Button
            color={ButtonColor.Secondary}
            data-testid="log-out-button"
            label={settings.headerLogOut}
            onClick={() => logoutRedirect(true)}
          />

          {accounts && accounts.length > 1 && (
            <>
              <Divider color={Color.Gray2} marginY={spacing.x2} />
              <Typography type={TypographyType.ExpressiveL} text={settings.headerLoginAs} />

              <div
                className={classNames(styles.accountsWrapper, {
                  [styles.accountsWrapperMobile]: isMobile,
                  [styles.accountsWrapperDesktop]: !isMobile,
                })}
              >
                {accounts.map((ratorAccount: RatorAccount) => {
                  const selected = selectedRatorAccount
                    ? selectedRatorAccount?.userId === ratorAccount.userId
                    : ratorAccount.userId === account?.userId;
                  return (
                    <RadioLinkButton
                      data-testid={`radio-button-account-${ratorAccount.email}`}
                      checked={selected}
                      key={ratorAccount.userId}
                      aria-label="account-selection-radio-button"
                      label={ratorAccount.email}
                      selected={selected}
                      onChange={() => handleSelectAccount(ratorAccount)}
                    />
                  );
                })}
              </div>

              <Divider color={Color.Gray2} marginY={spacing.x2} />
              <Button
                disabled={!selectedRatorAccount}
                data-testid="choose-account-button"
                label={settings.headerSelectAccount}
                onClick={handleSubmitAccount}
              />
            </>
          )}
        </div>
      </div>
    </MainIconLink>
  );
};

export default MyPagesMenu;
