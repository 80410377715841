import styled from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { hexToRgba } from '@/global/utils/styleHelpers';

const radioCardBorderColor = {
  DISABLED: Color.DarkGray2,
  CHECKED: Color.Blue,
  FOCUS: Color.Ocean,
  HOVER: Color.DarkGray1,
  DEFAULT: Color.DarkGray2,
};

export const RadioCardContainer = styled.div<{ $disabled?: boolean; $checked?: boolean }>`
  display: flex;
  overflow-wrap: anywhere;
  position: relative;
  padding: ${spacing.x2};
  margin: ${spacing.x1} 0;
  background-color: ${Color.White};
  border-radius: ${BorderRadius.Medium};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $disabled }) => {
    if ($disabled) return radioCardBorderColor.DISABLED;
    return radioCardBorderColor.DEFAULT;
  }};

  ${({ $disabled }) =>
    $disabled &&
    `
      * {
        color: ${Color.DarkGray2} !important;
      }
  `};

  ${({ $checked, $disabled }) =>
    $checked &&
    !$disabled &&
    `
      border-color: ${Color.Transparent};

     .radio-card-override-border {
        display: block;
      }
  `};

  &:focus-within {
    border-color: ${Color.Transparent};
    .radio-card-override-border {
      display: block;
      border-color: ${radioCardBorderColor.FOCUS};
    }

    &:hover {
      border-color: ${Color.Transparent};
    }
  }

  &:hover {
    ${({ $checked, $disabled }) =>
      !$disabled &&
      !$checked &&
      `
    border-color: ${radioCardBorderColor.HOVER};
    background-color: ${hexToRgba(Color.DarkGray1, 0.08)};`};
  }
`;

export const RadioCardOverrideBorder = styled.div`
  display: none;
  position: absolute;
  border-style: solid;
  border-radius: ${BorderRadius.Medium};
  border-width: 2px;
  border-color: ${radioCardBorderColor.CHECKED};
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
