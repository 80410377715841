import Typography from '@/newComponents/Typography/Typography';
import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, shadows, spacing } from '@/global/style/variables';

import DropDownMenuAnimationWrapper from '@/components/DropDownMenuAnimationWrapper/DropDownMenuAnimationWrapper';

const HEADER_INNER_HEIGHT = 30;
const HEADER_PADDING = 25;
const HEADER_HEIGHT = HEADER_INNER_HEIGHT + HEADER_PADDING * 2;

export const PositionWrapper = styled.div`
  height: ${HEADER_HEIGHT}px;
  background: ${Color.White};
`;

export const ScrollWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: block;
  width: 100%;
  padding-top: ${HEADER_PADDING}px;
  padding-bottom: ${HEADER_PADDING}px;

  transition: all 0.5s ease;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    background: ${Color.White};

    opacity: 0;

    position: absolute;
    top: 0;
    box-shadow: ${shadows.default};

    transition: all 0.5s ease;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 110vh;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 1s ease;
    pointer-events: none;
    opacity: 0;
    z-index: 20;
  }

  &[data-scroll-position='not_top'] {
    color: ${Color.Hallon1};
    &:before {
      opacity: 1;
    }
  }

  transform: translateY(0);
  &[data-scroll-state='down'] {
    transform: translateY(-135%);
  }

  &[data-dropdown-visible='true'] {
    &:before,
    &:after {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export const TitleIconClickableWrapper = styled.button`
  padding: 0 ${spacing.x2};
  width: 100%;
  height: ${HEADER_INNER_HEIGHT}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  color: ${Color.Hallon1};
  pointer-events: all;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Header = styled(Typography)`
  color: ${Color.Hallon1};
  margin: 0;
  z-index: 2;
`;

// Dropdown

export const DropDownWrapper = styled(DropDownMenuAnimationWrapper)`
  width: 100%;
  overflow: hidden;

  & > div {
    top: 0;
    width: calc(100% - 16px);
    position: relative;
    margin: 0 8px;
  }

  [data-dropdown-visible='false'] & {
    pointer-events: none;
  }

  ${above.sm`
    max-width: 375px;
  `}
`;
